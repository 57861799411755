import React from "react";
import { Link } from 'react-router-dom';
import regisvendorLayout from "../RegisVendorLayout";
import ModalComponent from "../../../components/ModalComponent";

class IzinVendorPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            izinJenis:'',
            izinNomor:'',
            izinTanggal:'',
            izinBerakhir:'',
            izinInstansi:'',
            izinBerlaku:'',
            izinBidang:'',
            izin:[],
            id:'',
            alertSucces: false,
            alermessage: '',
            bgalert: 'bg-primary',
            btnalert: 'btn-primary',
            typepass:'password'
        };
        this.handleSaveIzin = this.handleSaveIzin.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }
    
    componentDidMount() {
        this.IzinVendor();
    }
      
    IzinVendor() {           
        const usToken = localStorage.getItem('UsToken'); 
        fetch("https://vendor.delpis.online/api/vendor/izin", {
            method: "post",
            body: JSON.stringify({
                token: usToken
            }),
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((response) => {
            this.setState({
                izin: response['izinData'] || []
            });
            
            localStorage.setItem("IzinVendor", JSON.stringify(this.state.izin));
        })
    }
    
    FormatIzinTgl(e){
        this.setState({izinTanggal: e.target.value});
    }
    
    adddata(){
        this.setState({
            izinJenis:'',
            izinNomor:'',
            izinTanggal:'',
            izinBerakhir:'',
            izinInstansi:'',
            izinBerlaku:'',
            izinBidang:'',
        })
    }


    viewedit(item){
        this.setState({
            izinJenis: item.type || '',
            izinNomor: item.permit_number || '',
            izinTanggal: item.start_date || '',
            izinBerakhir: item.end_date || '',
            izinInstansi: item.licesnsing_agency || '',
            izinBerlaku: item.exp_permit || '',
            izinBidang: item.business_name || '',
            id: item.permit_number || '',
            editsts:1
        })
    }

    delete(nomorizin){
        this.setState({
            id : nomorizin
        })  
        // this.setState({groupuserdata})
    }

    handleDelete(event){
        const data = this.state.izin.filter(i => i.permit_number !== this.state.id)
        this.state.izin = data;
        localStorage.setItem("IzinVendor", JSON.stringify(this.state.izin));
        this.setState({
            person : data,
            alertSucces : true,
            alermessage: "Deleted Succes",
            bgalert: "bg-danger",
            btnalert: "btn-danger"})
    }

    handleSaveIzin(event){
        event.preventDefault();
        this.setState({
            bgalert: "bg-warning",
            btnalert: "btn-warning"
        })

        if(this.state.izinJenis == "" || this.state.izinJenis == null){
            this.setState({
                alertSucces : true,
                alermessage: "Jenis Izin harus di isi"
            })  
            return;
        }

        if(this.state.izinNomor == "" || this.state.izinNomor == null){
            this.setState({
                alertSucces : true,
                alermessage: "Nomor Izin harus di isi"
            })  
            return;
        }

        if(this.state.izinTanggal == "" || this.state.izinTanggal == null){
            this.setState({
                alertSucces : true,
                alermessage: "Tanggal Izin harus di isi"
            })  
            return;
        }

        if(this.state.izinBerakhir == "" || this.state.izinBerakhir == null){
            this.setState({
                alertSucces : true,
                alermessage: "Nama Contact harus di isi"
            })  
            return;
        }

        if(this.state.izinInstansi == "" || this.state.izinInstansi == null){
            this.setState({
                alertSucces : true,
                alermessage: "Pemberi Izin harus di isi"
            })  
            return;
        }

        if(this.state.izinBerlaku == "" || this.state.izinBerlaku == null){
            this.setState({
                alertSucces : true,
                alermessage: "Masa Berlaku Izin harus di isi"
            })  
            return;
        }

        if(this.state.izinBidang == "" || this.state.izinBidang == null){
            this.setState({
                alertSucces : true,
                alermessage: "Bidang Usaha harus di isi"
            })  
            return;
        }
        
        if(this.state.editsts === 1){
            const newData = Object.values(this.state.izin);

            const index = newData.findIndex(item => item.permit_number === this.state.id);
            console.log('index : ' + index);
            // Lakukan perubahan pada salinan array
            if (index !== -1) {
                newData[index] = { ...newData[index],
                    type: this.state.izinJenis,
                    permit_number: this.state.izinNomor,
                    start_date: this.state.izinTanggal,
                    end_date: this.state.izinBerakhir,
                    licesnsing_agency: this.state.izinInstansi,
                    exp_permit: this.state.izinBerlaku,
                    business_name: this.state.izinBidang
                };
                // console.log(newData);
                this.state.izin = newData; // Update state dengan array yang sudah diubah
                localStorage.setItem("IzinVendor", JSON.stringify(this.state.izin));
            }
        } else {
            const oldArray = Object.values(this.state.izin);
            var newStateArray = oldArray.slice();
            newStateArray.push({
                "type": this.state.izinJenis,
                "permit_number": this.state.izinNomor,
                "start_date": this.state.izinTanggal,
                "end_date": this.state.izinBerakhir,
                "licesnsing_agency": this.state.izinInstansi,
                "exp_permit": this.state.izinBerlaku,
                "business_name": this.state.izinBidang
                });
            this.state.izin = newStateArray;
    
            localStorage.setItem("IzinVendor", JSON.stringify(this.state.izin));
        }
        
        this.TombolElement.click();
    }

    modalFooterDeleted(){      

        return <>
            <div style={{width:"100%"}}>
                <button className="btn btn-success" onClick={this.handleDelete}
                    data-bs-dismiss="modal">Yes</button>
            &nbsp;
            <button className="btn btn-danger"
                data-bs-dismiss="modal">No</button>
            </div>
        </>;
    }

    modalDeleted(){
        return <>
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                <form>
                    <div className="mb-3">
                        <label htmlFor="group_name" className="form-label">Apakah anda yakin ingin menghapus data?</label>
                    </div>
                </form>
            </div>
        </>;
    }

    modalFooterContent(){
        return <>
            <div style={{width:"100%"}}>
                <button className="btn btn-success" onClick={this.handleSaveIzin}>Save</button> 
                &nbsp;
                <button className="btn btn-danger" data-bs-dismiss="modal"
                    ref={tombol => this.TombolElement = tombol}>Cancel</button> 
            </div>
        </>;
    }

    modalContent(){
        return <>
            {/* <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Group User Data</button>
                </li>
            </ul> */}
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                    <form>
                        <div className="mb-3">
                            <label htmlFor="IzinJenis" className="form-label">Jenis Izin <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="IzinJenis" aria-describedby="Group_name"
                                onChange={(e) => this.setState({izinJenis : e.target.value})}
                                value={ this.state.izinJenis }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="IzinNomor" className="form-label">Nomor Izin <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="IzinNomor" aria-describedby="Group_name"
                                onChange={(e) => this.setState({izinNomor : e.target.value})}
                                value={ this.state.izinNomor }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="IzinTanggal" className="form-label">Tanggal Izin <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="date" className="form-control" id="IzinTanggal" aria-describedby="Group_name"
                                onChange={(e) => this.setState({izinTanggal : e.target.value})}
                                value={ this.state.izinTanggal }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="IzinBerakhir" className="form-label">Tanggal Berakhir <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="date" className="form-control" id="ContactPosisi" aria-describedby="Group_name"
                                onChange={(e) => this.setState({izinBerakhir : e.target.value})}
                                value={ this.state.izinBerakhir }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="IzinInstansi" className="form-label">Instansi Pemberi Izin <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="IzinInstansi" aria-describedby="Group_name"
                                onChange={(e) => this.setState({izinInstansi : e.target.value})}
                                value={ this.state.izinInstansi }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="IzinMasaBerlaku" className="form-label">Masa Berlaku Izin Usaha <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="IzinMasaBerlaku" aria-describedby="Group_name"
                                onChange={(e) => this.setState({izinBerlaku : e.target.value})}
                                value={ this.state.izinBerlaku }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="IzinBidang" className="form-label">Bidang Usaha <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="IzinBidang" aria-describedby="Group_name"
                                onChange={(e) => this.setState({izinBidang : e.target.value})}
                                value={ this.state.izinBidang }/>
                        </div>
                    </form>
                </div>
            </div>
        </>;
    }

    render(){
        var no = 1;

        const VendorIzin = JSON.parse(localStorage.getItem('IzinVendor'));
        // const VendorContact = localStorage.getItem('ContactVendor');
        if(VendorIzin){
            this.state.izin = VendorIzin;
            // console.log(this.state.contact);
        }
        // console.log(this.state.contact);
        const IsiVendor = this.state.izin.map((item, i) => (

            <tr>
                <td>{ no++ }</td>
                <td>{ item.type }</td>
                <td>{ item.permit_number }</td>  
                <td>{ item.start_date }</td>
                <td>{ item.end_date }</td>              
                <td>{ item.licesnsing_agency }</td>
                <td>{ item.exp_permit }</td>
                <td>{ item.business_name }</td>                
                <td>
                    <div className="dropdown table-action-dropdown">
                        <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButtonSM" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v" aria-hidden="true"></i></button>
                        <ul className="dropdown-menu dropdown-prof" aria-labelledby="dropdownMenuButtonSM">
                            <li><a className="dropdown-item" href="#" onClick={this.viewedit.bind(this, item)}
                                data-bs-toggle="modal" data-bs-target="#exampleModalDefault">
                                <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Edit</a></li>
                            <div className="dropdown-divider"></div>        
                            <li><a className="dropdown-item text-danger" href="#" onClick={this.delete.bind(this, item.permit_number)}
                                data-bs-toggle="modal" data-bs-target="#deletedModal">
                                <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete</a></li>                            
                        </ul>
                    </div>
                </td>
            </tr>
        ));

        return <>
            <div>
                <div className="row bg-primary text-white">
                    <center><h1>Izin Usaha</h1></center>
                </div>
                <div className="row">
                    &nbsp;
                </div>
                <div className="row">
                    <div className="col-6">
                        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalDefault"
                            onClick={this.adddata.bind(this)}>
                            Add
                        </button>
                    </div>
                </div>

                <div className="row">
                    &nbsp;
                </div>

                <div className="row">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th className="Col-klik">Jenis Izin</th>
                                <th className="Col-klik">Nomor Izin</th>
                                <th className="Col-klik">Tanggal Izin</th>
                                <th className="Col-klik">Tanggal Berakhir</th>
                                <th className="Col-klik">Instansi Pemberi Izin</th>
                                <th className="Col-klik">Masa Berlaku Izin Usaha</th>
                                <th className="Col-klik">Bidang Usaha</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { IsiVendor }
                        </tbody>
                    </table>
                </div>

                <div className="text-right text-lg-start mt-4 pt-2">
                    <Link to="/RegisVendorLandasan" type="button" className="btn btn-primary btn-lg">Next</Link>
                </div>
            </div>
                
            <ModalComponent title="Deleted Confirmation" footerContent={this.modalFooterDeleted()} content={this.modalDeleted()} dataBsBackdrop="static" id="deletedModal"/>
            <ModalComponent title="Add Izin Usaha" footerContent={this.modalFooterContent()} content={this.modalContent()} dataBsBackdrop="static" id="exampleModalDefault"/>
                
            {this.state.alertSucces && 
                <div className="ModalAlert">
                    <div className="alertNew">
                        <div className="modal-content">                   
                            <div className={`modal-header ${this.state.bgalert}`}>
                                <h5 className="modal-title" id="exampleModalLabel">Alert Message</h5>
                                <button type="button" className="btn-close" aria-label="Close"
                                    onClick={(e) => this.setState({alertSucces : false})}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div className="modal-body text-black">
                                <center><h1>{ this.state.alermessage }</h1></center>
                            </div>            
                            <div style={{width:"100%"}}>
                                <center>
                                <button className={`btn ${this.state.btnalert}`} onClick={(e) => this.setState({alertSucces : false})}>
                                    OK</button> 
                                </center>
                            </div>
                            <br></br>
                        </div>
                    </div>
                </div>
                }
        </>
    }
}

export default regisvendorLayout(IzinVendorPage);