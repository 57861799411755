// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divider:after,
.divider:before {
  content: "";
  flex: 1 1;
  height: 1px;
  background: #eee;
}
.h-custom {
  height: calc(100% - 73px);
}

.mata {
  cursor: pointer;
  padding: 15px 0 0 0;
  position: absolute;
  max-width: 15px;
}

@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}

.login-form {
  background: #fff;
  padding: 10px 5%;
  border-radius: var(--border-radius);
}

.login-form h1 {
  font-size: 30px !important;
}

.reset-password-section {
  background-color: #fff;
  padding: 15px 5%;
  border-radius: var(--border-radius);
}`, "",{"version":3,"sources":["webpack://./src/assets/css/login.css"],"names":[],"mappings":"AAAA;;EAEE,WAAW;EACX,SAAO;EACP,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,mCAAmC;AACrC;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,mCAAmC;AACrC","sourcesContent":[".divider:after,\n.divider:before {\n  content: \"\";\n  flex: 1;\n  height: 1px;\n  background: #eee;\n}\n.h-custom {\n  height: calc(100% - 73px);\n}\n\n.mata {\n  cursor: pointer;\n  padding: 15px 0 0 0;\n  position: absolute;\n  max-width: 15px;\n}\n\n@media (max-width: 450px) {\n  .h-custom {\n    height: 100%;\n  }\n}\n\n.login-form {\n  background: #fff;\n  padding: 10px 5%;\n  border-radius: var(--border-radius);\n}\n\n.login-form h1 {\n  font-size: 30px !important;\n}\n\n.reset-password-section {\n  background-color: #fff;\n  padding: 15px 5%;\n  border-radius: var(--border-radius);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
