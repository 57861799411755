import React from "react";
import { Link } from 'react-router-dom';
import regisvendorLayout from "../RegisVendorLayout";
import ModalComponent from "../../../components/ModalComponent";

class LandasanVendorPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            nodok:'',
            tgldok:'',
            notaris:'',
            deed: [],
            id:'',
            alertSucces: false,
            alermessage: '',
            bgalert: 'bg-primary',
            btnalert: 'btn-primary',
            typepass:'password'
        };
        this.handleSave = this.handleSave.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }
    
    componentDidMount() {
        this.DeedVendor();
    }
      
    DeedVendor() {           
        const usToken = localStorage.getItem('UsToken'); 
        fetch("https://vendor.delpis.online/api/vendor/deed", {
            method: "post",
            body: JSON.stringify({
                token: usToken
            }),
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((response) => {
            this.setState({
                deed: response['deedData'] || []
            });
            
            localStorage.setItem("DeedVendor", JSON.stringify(this.state.deed));
        })
    }

    adddata(){
        this.setState({
            nodok:'',
            tgldok:'',
            notaris:'',
        })
    }

    viewedit(item){
        this.setState({
            nodok: item.deed_no || '',
            tgldok: item.date || '',
            notaris: item.notary || '',
            id: item.deed_no || '',
            editsts:1
        })
    }

    delete(dokno){
        this.setState({
            id : dokno
        })  
        // this.setState({groupuserdata})
    }

    handleDelete(event){
        const data = this.state.deed.filter(i => i.deed_no !== this.state.id)
        this.state.deed = data;
        localStorage.setItem("DeedVendor", JSON.stringify(this.state.deed));
        this.setState({
            person : data,
            alertSucces : true,
            alermessage: "Deleted Succes",
            bgalert: "bg-danger",
            btnalert: "btn-danger"})
    }

    handleSave(event){
        event.preventDefault();
        this.setState({
            bgalert: "bg-warning",
            btnalert: "btn-warning"
        })

        if(this.state.nodok == "" || this.state.nodok == null){
            this.setState({
                alertSucces : true,
                alermessage: "Nomor Dokumen harus di isi"
            })  
            return;
        }

        if(this.state.tgldok == "" || this.state.tgldok == null){
            this.setState({
                alertSucces : true,
                alermessage: "Tanggal Dokumen harus di isi"
            })  
            return;
        }

        if(this.state.notaris == "" || this.state.notaris == null){
            this.setState({
                alertSucces : true,
                alermessage: "Nama Notaris harus di isi"
            })  
            return;
        }
        
        if(this.state.editsts === 1){
            const newData = Object.values(this.state.deed);

            const index = newData.findIndex(item => item.deed_no === this.state.id);
            console.log('index : ' + index);
            // Lakukan perubahan pada salinan array
            if (index !== -1) {
                newData[index] = { ...newData[index],
                    deed_no: this.state.nodok,
                    date: this.state.tgldok,
                    notary: this.state.notaris
                };
                // console.log(newData);
                this.state.deed = newData; // Update state dengan array yang sudah diubah
                localStorage.setItem("DeedVendor", JSON.stringify(this.state.deed));
            }
        } else {
        
            const oldArray = Object.values(this.state.deed);
            var newStateArray = oldArray.slice();
            newStateArray.push({
                "deed_no": this.state.nodok,
                "date": this.state.tgldok,
                "notary": this.state.notaris
                });
            this.state.deed = newStateArray;

            localStorage.setItem("DeedVendor", JSON.stringify(this.state.deed));
        }

        this.TombolElement.click();
    }
    
    modalFooterDeleted(){      

        return <>
            <div style={{width:"100%"}}>
                <button className="btn btn-success" onClick={this.handleDelete}
                    data-bs-dismiss="modal">Yes</button>
            &nbsp;
            <button className="btn btn-danger"
                data-bs-dismiss="modal">No</button>
            </div>
        </>;
    }

    modalDeleted(){
        return <>
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                <form>
                    <div className="mb-3">
                        <label htmlFor="group_name" className="form-label">Apakah anda yakin ingin menghapus data?</label>
                    </div>
                </form>
            </div>
        </>;
    }

    modalFooterContent(){
        return <>
            <div style={{width:"100%"}}>
                <button className="btn btn-success" onClick={this.handleSave}>Save</button> 
                &nbsp;
                <button className="btn btn-danger" ref={tombol => this.TombolElement = tombol}
                    data-bs-dismiss="modal">Cancel</button> 
            </div>
        </>;
    }

    modalContent(){
        return <>
            {/* <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Group User Data</button>
                </li>
            </ul> */}
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                    <form>
                        <div className="mb-3">
                            <label htmlFor="NomorDokumen" className="form-label">Nomor Dokumen <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="NomorDokumen" aria-describedby="Group_name"
                                onChange={(e) => this.setState({nodok : e.target.value})}
                                value={ this.state.nodok }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="TglDokumen" className="form-label">Tanggal Dokumen <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="date" className="form-control" id="TglDokumen" aria-describedby="Group_name"
                                onChange={(e) => this.setState({tgldok : e.target.value})}
                                value={ this.state.tgldok }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="NamaNotaris" className="form-label">Nama Notaris <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="NamaNotaris" aria-describedby="Group_name"
                                onChange={(e) => this.setState({notaris : e.target.value})}
                                value={ this.state.notaris }/>
                        </div>
                    </form>
                </div>
            </div>
        </>;
    }

    render(){
        var no = 1;

        const VendorDeed = JSON.parse(localStorage.getItem('DeedVendor'));
        // const VendorContact = localStorage.getItem('ContactVendor');
        if(VendorDeed){
            this.state.deed = VendorDeed;
            // console.log(this.state.contact);
        }
        // console.log(this.state.contact);
        const DeedVendor = this.state.deed.map((item, i) => (

            <tr>
                <td>{ no++ }</td>
                <td>{ item.deed_no }</td>
                <td>{ item.date }</td>  
                <td>{ item.notary }</td>       
                <td>
                    <div className="dropdown table-action-dropdown">
                        <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButtonSM" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v" aria-hidden="true"></i></button>
                        <ul className="dropdown-menu dropdown-prof" aria-labelledby="dropdownMenuButtonSM">
                            <li><a className="dropdown-item" href="#" onClick={this.viewedit.bind(this, item)}
                                data-bs-toggle="modal" data-bs-target="#exampleModalDefault">
                                <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Edit</a></li>
                            <div className="dropdown-divider"></div>        
                            <li><a className="dropdown-item text-danger" href="#" onClick={this.delete.bind(this, item.deed_no)}
                                data-bs-toggle="modal" data-bs-target="#deletedModal">
                                <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete</a></li>                            
                        </ul>
                    </div>
                </td>
            </tr>
        ));

        return <>
            <div>
                <div className="row bg-primary text-white">
                    <center><h1>Landasan Hukum</h1></center>
                </div>
                <div className="row">
                    &nbsp;
                </div>
                <div className="row">
                    <div className="col-6">
                        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalDefault"
                            onClick={this.adddata.bind(this)}>
                            Add
                        </button>
                    </div>
                </div>

                <div className="row">
                    &nbsp;
                </div>

                <div className="row">                    
                    <table className="table">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th className="Col-klik">Nomor Dokumen</th>
                                <th className="Col-klik">Tanggal Dokumen</th>
                                <th className="Col-klik">Nama Notaris</th>
                                {/* <th>Status</th> */}
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { DeedVendor }
                        </tbody>
                    </table>
                </div>

                <div className="text-right text-lg-start mt-4 pt-2">
                    <Link to="/RegisVendorPengurus" type="button" className="btn btn-primary btn-lg">Next</Link>
                </div>
            </div>
                
            <ModalComponent title="Deleted Confirmation" footerContent={this.modalFooterDeleted()} content={this.modalDeleted()} dataBsBackdrop="static" id="deletedModal"/>
            <ModalComponent title="Add Landasan Hukum" footerContent={this.modalFooterContent()} content={this.modalContent()} dataBsBackdrop="static" id="exampleModalDefault"/>
                
            {this.state.alertSucces && 
                <div className="ModalAlert">
                    <div className="alertNew">
                        <div className="modal-content">                   
                            <div className={`modal-header ${this.state.bgalert}`}>
                                <h5 className="modal-title" id="exampleModalLabel">Alert Message</h5>
                                <button type="button" className="btn-close" aria-label="Close"
                                    onClick={(e) => this.setState({alertSucces : false})}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div className="modal-body text-black">
                                <center><h1>{ this.state.alermessage }</h1></center>
                            </div>            
                            <div style={{width:"100%"}}>
                                <center>
                                <button className={`btn ${this.state.btnalert}`} onClick={(e) => this.setState({alertSucces : false})}>
                                    OK</button> 
                                </center>
                            </div>
                            <br></br>
                        </div>
                    </div>
                </div>
                }
        </>
    }
}

export default regisvendorLayout(LandasanVendorPage);