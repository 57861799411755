import React from "react";
import adminLayout from "../hoc/adminLayout";
import { Link } from 'react-router-dom';

class DashboardPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
          jumUser: 0,
          grupuser: 0
        }
    }

    componentDidMount() {
       const UsData = JSON.parse(localStorage.getItem('UsData'));
        if(UsData !== null){
          this.setState({grupuser : UsData['group_users_id'] || ''})
        }
        this.UserCount();
    }
      
    UserCount() {
        fetch('https://admin.delpis.online/api/users/count')
        .then((response) => response.json())
        .then((response) => this.setState({jumUser : response['jumUser'] || []}))
    }

    render(){
      console.log(this.state.grupuser);
      
      return <>
      <div className="row">
        {
          this.state.grupuser === 2 ?
            ''
          :
          <div className="col-xl-3 col-sm-6 mb-3">
            <div className="card text-white bg-primary o-hidden h-100">
              <div className="card-body">
                <div className="card-body-icon">
                  <i className="fa fa-fw fa-user"></i>
                </div>
                <div className="mr-5">Total User : { this.state.jumUser }</div>
              </div>
              <Link to="/users">
              <a className="card-footer text-white clearfix small z-1" href="#">
              <span className="float-left">View Details</span>
                <span className="float-right">
                  <i className="fa fa-angle-right"></i>
                </span>
              </a>
              </Link>
            </div>
          </div>
        }

        {/* <div className="col-xl-3 col-sm-6 mb-3">
          <div className="card text-white bg-warning o-hidden h-100">
            <div className="card-body">
              <div className="card-body-icon">
                <i className="fa fa-fw fa-list"></i>
              </div>
              <div className="mr-5">11 New Tasks!</div>
            </div>
            <a className="card-footer text-white clearfix small z-1" href="#">
              <span className="float-left">View Details</span>
              <span className="float-right">
                <i className="fa fa-angle-right"></i>
              </span>
            </a>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 mb-3">
          <div className="card text-white bg-success o-hidden h-100">
            <div className="card-body">
              <div className="card-body-icon">
                <i className="fa fa-fw fa-shopping-cart"></i>
              </div>
              <div className="mr-5">123 New Orders!</div>
            </div>
            <a className="card-footer text-white clearfix small z-1" href="#">
              <span className="float-left">View Details</span>
              <span className="float-right">
                <i className="fa fa-angle-right"></i>
              </span>
            </a>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 mb-3">
          <div className="card text-white bg-danger o-hidden h-100">
            <div className="card-body">
              <div className="card-body-icon">
                <i className="fa fa-fw fa-support"></i>
              </div>
              <div className="mr-5">13 New Tickets!</div>
            </div>
            <a className="card-footer text-white clearfix small z-1" href="#">
              <span className="float-left">View Details</span>
              <span className="float-right">
                <i className="fa fa-angle-right"></i>
              </span>
            </a>
          </div>
        </div> */}
      </div>
        </>
    }
}

export default adminLayout(DashboardPage);