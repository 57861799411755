import React from "react";
import { Link } from 'react-router-dom';
import regisvendorLayout from "../RegisVendorLayout";
import ModalComponent from "../../../components/ModalComponent";

class BankVendorPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            bankNorek:'',
            bankUang:'',
            bankName:'',
            bank: [],
            id:'',
            alertSucces: false,
            alermessage: '',
            bgalert: 'bg-primary',
            btnalert: 'btn-primary',
            typepass:'password'
        };
        this.handleSave = this.handleSave.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    adddata(){
        this.setState({
            bankNorek:'',
            bankUang:'',
            bankName:'',
        })
    }

    viewedit(item){
        this.setState({
            bankNorek: item.no_rekening || '',
            bankUang: item.mata_uang || '',
            bankName: item.nama_bank || '',
            id: item.no_rekening || '',
            editsts:1
        })
    }

    delete(rekno){
        this.setState({
            id : rekno
        })  
        // this.setState({groupuserdata})
    }
    
    componentDidMount() {
        this.BankVendor();
    }
      
    BankVendor() {           
        const usToken = localStorage.getItem('UsToken'); 
        fetch("https://vendor.delpis.online/api/vendor/bank", {
            method: "post",
            body: JSON.stringify({
                token: usToken
            }),
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((response) => {
            this.setState({
                bank: response['bankData'] || []
            });
            
            localStorage.setItem("BankVendor", JSON.stringify(this.state.bank));
        })
    }

    handleDelete(event){
        const data = this.state.bank.filter(i => i.no_rekening !== this.state.id)
        this.state.bank = data;
        localStorage.setItem("BankVendor", JSON.stringify(this.state.bank));
        this.setState({
            person : data,
            alertSucces : true,
            alermessage: "Deleted Succes",
            bgalert: "bg-danger",
            btnalert: "btn-danger"})
    }

    handleSave(event){
        event.preventDefault();
        this.setState({
            bgalert: "bg-warning",
            btnalert: "btn-warning"
        })

        if(this.state.bankNorek == "" || this.state.bankNorek == null){
            this.setState({
                alertSucces : true,
                alermessage: "Nomor Rekening harus di isi"
            })  
            return;
        }

        if(this.state.bankUang == "" || this.state.bankUang == null){
            this.setState({
                alertSucces : true,
                alermessage: "Mata Uang harus di isi"
            })  
            return;
        }

        if(this.state.bankName == "" || this.state.bankName == null){
            this.setState({
                alertSucces : true,
                alermessage: "Nama Bank harus di isi"
            })  
            return;
        }
        
        if(this.state.editsts === 1){
            const newData = Object.values(this.state.bank);

            const index = newData.findIndex(item => item.no_rekening === this.state.id);
            console.log('index : ' + index);
            // Lakukan perubahan pada salinan array
            if (index !== -1) {
                newData[index] = { ...newData[index],
                    no_rekening: this.state.bankNorek,
                    mata_uang: this.state.bankUang,
                    nama_bank: this.state.bankName
                };
                // console.log(newData);
                this.state.bank = newData; // Update state dengan array yang sudah diubah
                localStorage.setItem("BankVendor", JSON.stringify(this.state.bank));
            }
        } else {
        
            const oldArray = Object.values(this.state.bank);
            var newStateArray = oldArray.slice();
            newStateArray.push({
                "no_rekening": this.state.bankNorek,
                "mata_uang": this.state.bankUang,
                "nama_bank": this.state.bankName
                });
            this.state.bank = newStateArray;

            localStorage.setItem("BankVendor", JSON.stringify(this.state.bank));
        }

        this.TombolElement.click();
    }
    
    modalFooterDeleted(){      

        return <>
            <div style={{width:"100%"}}>
                <button className="btn btn-success" onClick={this.handleDelete}
                    data-bs-dismiss="modal">Yes</button>
            &nbsp;
            <button className="btn btn-danger"
                data-bs-dismiss="modal">No</button>
            </div>
        </>;
    }

    modalDeleted(){
        return <>
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                <form>
                    <div className="mb-3">
                        <label htmlFor="group_name" className="form-label">Apakah anda yakin ingin menghapus data?</label>
                    </div>
                </form>
            </div>
        </>;
    }

    modalFooterContent(){
        return <>
            <div style={{width:"100%"}}>
                <button className="btn btn-success" onClick={this.handleSave}>Save</button> 
                &nbsp;
                <button className="btn btn-danger" ref={tombol => this.TombolElement = tombol}
                    data-bs-dismiss="modal">Cancel</button> 
            </div>
        </>;
    }

    modalContent(){
        return <>
            {/* <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Group User Data</button>
                </li>
            </ul> */}
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                    <form>
                        <div className="mb-3">
                            <label htmlFor="BankNorek" className="form-label">No Rekening <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="BankNorek" aria-describedby="Group_name"
                                onChange={(e) => this.setState({bankNorek : e.target.value})}
                                value={ this.state.bankNorek }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="BankUang" className="form-label">Mata Uang <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="ContactPhone" aria-describedby="Group_name"
                                onChange={(e) => this.setState({bankUang : e.target.value})}
                                value={ this.state.bankUang }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="BankName" className="form-label">Nama Bank <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="BankName" aria-describedby="Group_name"
                                onChange={(e) => this.setState({bankName : e.target.value})}
                                value={ this.state.bankName }/>
                        </div>
                    </form>
                </div>
            </div>
        </>;
    }

    render(){
        var no = 1;

        const VendorBank = JSON.parse(localStorage.getItem('BankVendor'));
        // const VendorContact = localStorage.getItem('ContactVendor');
        if(VendorBank){
            this.state.bank = VendorBank;
            // console.log(this.state.contact);
        }
        // console.log(this.state.contact);
        const BankVendor = this.state.bank.map((item, i) => (

            <tr>
                <td>{ no++ }</td>
                <td>{ item.no_rekening }</td>
                <td>{ item.mata_uang }</td>  
                <td>{ item.nama_bank }</td>       
                <td>
                    <div className="dropdown table-action-dropdown">
                        <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButtonSM" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v" aria-hidden="true"></i></button>
                        <ul className="dropdown-menu dropdown-prof" aria-labelledby="dropdownMenuButtonSM">
                            <li><a className="dropdown-item" href="#" onClick={this.viewedit.bind(this, item)}
                                data-bs-toggle="modal" data-bs-target="#exampleModalDefault">
                                <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Edit</a></li>
                            <div className="dropdown-divider"></div>        
                            <li><a className="dropdown-item text-danger" href="#" onClick={this.delete.bind(this, item.no_rekening)}
                                data-bs-toggle="modal" data-bs-target="#deletedModal">
                                <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete</a></li>                            
                        </ul>
                    </div>
                </td>
            </tr>
        ));

        return <>
            <div>
                <div className="row bg-primary text-white">
                    <center><h1>Bank Account</h1></center>
                </div>
                <div className="row">
                    &nbsp;
                </div>
                <div className="row">
                    <div className="col-6">
                        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalDefault"
                            onClick={this.adddata.bind(this)}>
                            Add
                        </button>
                    </div>
                </div>

                <div className="row">
                    &nbsp;
                </div>

                <div className="row">                    
                    <table className="table">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th className="Col-klik">No. Rekening</th>
                                <th className="Col-klik">Mata Uang</th>
                                <th className="Col-klik">Nama Bank</th>
                                {/* <th>Status</th> */}
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { BankVendor }
                        </tbody>
                    </table>
                </div>

                <div className="text-right text-lg-start mt-4 pt-2">
                    <Link to="/RegisVendorIzin" type="button" className="btn btn-primary btn-lg">Next</Link>
                </div>
            </div>
                
            <ModalComponent title="Deleted Confirmation" footerContent={this.modalFooterDeleted()} content={this.modalDeleted()} dataBsBackdrop="static" id="deletedModal"/>
            <ModalComponent title="Add Bank Account" footerContent={this.modalFooterContent()} content={this.modalContent()} dataBsBackdrop="static" id="exampleModalDefault"/>
                

            {this.state.alertSucces && 
                <div className="ModalAlert">
                    <div className="alertNew">
                        <div className="modal-content">                   
                            <div className={`modal-header ${this.state.bgalert}`}>
                                <h5 className="modal-title" id="exampleModalLabel">Alert Message</h5>
                                <button type="button" className="btn-close" aria-label="Close"
                                    onClick={(e) => this.setState({alertSucces : false})}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div className="modal-body text-black">
                                <center><h1>{ this.state.alermessage }</h1></center>
                            </div>            
                            <div style={{width:"100%"}}>
                                <center>
                                <button className={`btn ${this.state.btnalert}`} onClick={(e) => this.setState({alertSucces : false})}>
                                    OK</button> 
                                </center>
                            </div>
                            <br></br>
                        </div>
                    </div>
                </div>
                }
        </>
    }
}

export default regisvendorLayout(BankVendorPage);