import React from "react";
import adminLayout from "../../hoc/adminLayout"
import "./../../assets/css/profile.css"
import { NavLink } from "react-router-dom";

const RegisVendorLayout = (ChildComponent) => {
    class UserProfilePageHoc extends React.Component {
        constructor(props){
            super(props);
    
            this.state = {}
        }

        render(){
            const path = window.location.pathname;
            const pathname = path.substring(1, 100)
            return <>
                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    {/* <li className="nav-item" role="presentation">
                        <NavLink to="/RegisVendor" className="text-body">
                        <button className={`nav-link ` + (pathname === 'RegisVendor'? `active` : ``)}>
                            Profile Perusahaan
                        </button>
                        </NavLink>
                    </li> */}
                    <li className="nav-item" role="presentation">
                        <NavLink to="/RegisVendorBank" className="text-body">
                        <button className={`nav-link ` + (pathname === 'RegisVendorBank'? `active` : ``)}>
                            Bank Account
                        </button>
                        </NavLink>
                    </li>
                    <li className="nav-item" role="presentation">
                        <NavLink to="/RegisVendorIzin" className="text-body">
                        <button className={`nav-link ` + (pathname === 'RegisVendorIzin'? `active` : ``)}>
                            Izin Usaha
                        </button>
                        </NavLink>
                    </li>
                    <li className="nav-item" role="presentation">
                        <NavLink to="/RegisVendorLandasan" className="text-body">
                        <button className={`nav-link ` + (pathname === 'RegisVendorLandasan'? `active` : ``)}>
                            Landasan Hukum
                        </button>
                        </NavLink>
                    </li>
                    <li className="nav-item" role="presentation">
                        <NavLink to="/RegisVendorPengurus" className="text-body">
                        <button className={`nav-link ` + (pathname === 'RegisVendorPengurus'? `active` : ``)}>
                            Pengurus Perusahaan
                        </button>
                        </NavLink>
                    </li>
                    <li className="nav-item" role="presentation">
                        <NavLink to="/RegisVendorSPT" className="text-body">
                        <button className={`nav-link ` + (pathname === 'RegisVendorSPT'? `active` : ``)}>
                            SPT Tahunan
                        </button>
                        </NavLink>
                    </li>
                    <li className="nav-item" role="presentation">
                        <NavLink to="/RegisVendorAhli" className="text-body">
                        <button className={`nav-link ` + (pathname === 'RegisVendorAhli'? `active` : ``)}>
                            Tenaga Ahli
                        </button>
                        </NavLink>
                    </li>
                    <li className="nav-item" role="presentation">
                        <NavLink to="/RegisVendorPengalaman" className="text-body">
                        <button className={`nav-link ` + (pathname === 'RegisVendorPengalaman'? `active` : ``)}>
                            Pengalaman
                        </button>
                        </NavLink>
                    </li>
                    <li className="nav-item" role="presentation">
                        <NavLink to="/RegisVendorAttach" className="text-body">
                        <button className={`nav-link ` + (pathname === 'RegisVendorAttach'? `active` : ``)}>
                            Attachment Dokumen
                        </button>
                        </NavLink>
                    </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <ChildComponent {...this.props} />
                </div>
            </>
        }
    }

    return adminLayout(UserProfilePageHoc);
}


export default RegisVendorLayout;