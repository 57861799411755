import React from "react";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Link, useLocation  } from 'react-router-dom';

class Sidebar extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            menudata:[],
            NamaUser:'',
            ParentPath:999
        }
        this.MenuList = this.MenuList.bind(this);
    }

    componentDidMount() {
        // console.log(window.location.pathname);
        this.MenuList();
    }

    LogOut(event) {
        event.preventDefault();
        localStorage.clear();
        window.location = "/";
    }
      
    MenuList() {
        const usToken = localStorage.getItem('UsToken');
        const usAccess = JSON.parse(localStorage.getItem('UsAccess'));
        const UsData = JSON.parse(localStorage.getItem('UsData'));
        var firstname = "";
        var lastname = "";
        if(usAccess){
            // console.log(111);
            const pathname = window.location.pathname;
            const MenuLvl = usAccess.filter(i => i.urlto == pathname.substring(1, 100));
            // console.log(MenuLvl);
            if(MenuLvl.length > 0){
                this.setState({
                    ParentPath: MenuLvl[0].parent_id
                })
            }

            if(UsData['firstname']){
                firstname = UsData['firstname'];
            }

            if(UsData['lastname']){
                lastname = UsData['lastname'];
            }

            this.setState({
                menudata : usAccess || [],
                NamaUser : firstname+' '+lastname
            })

        }else{           
            // console.log(222); 
            fetch("https://admin.delpis.online/api/users/menu", {
                method: "post",
                body: JSON.stringify({
                    usToken: usToken
                }),
                headers:{
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => response.json())
            .then((data) => {
    
                if(data['userdata']['firstname']){
                    firstname = data['userdata']['firstname'];
                }
    
                if(data['userdata']['lastname']){
                    lastname = data['userdata']['lastname'];
                }
    
                this.setState({
                    menudata : data['data'] || [],
                    NamaUser : firstname+' '+lastname
                })
                
                localStorage.setItem("UsAccess", JSON.stringify(data['data']));
                localStorage.setItem("UsData", JSON.stringify(data['userdata']));
            })
        }
    }

    render(){
        
        // const levelone = this.state.menudata.filter(i => i.level_menu === 1);
        const menudata = this.state.menudata.filter(i => i.level_menu === 1 && i.mview === true).map((item, i) => (
            <li className="dropdown">
                <a href="#" className="d-flex align-items-center text-decoration-none dropdown-toggle"
                    id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false"
                    onClick={(e) => this.setState({ParentPath : ''})}>
                    {/* <i className="fa fa-user"></i> */} {item.menu_name}
                </a>
                <ul className={`dropdown-menu position-relative tranform-menu ` + (this.state.ParentPath === item.menu_id ? `show` : ``)} aria-labelledby="dropdownUser2">
                {this.state.menudata.filter(i => i.level_menu === 2 && i.mview === true && i.parent_id === item.menu_id).map(function (items) {
                return (
                    <li className="mb-2">
                        <a>
                        <Link tag="a" className="" to={ '/'+items.urlto}>
                            {/* <i className="fa fa-bars"></i> */}
                            {items.menu_name}
                        </Link>
                        </a>
                    </li>
                );
                })}
                </ul>
            </li>
        ));

        return <div className="border-end sidenav" id="sidebar">
            <div className="sidebar-header border-bottom ">
                <Link to="/home">
                    <img alt="Alt content" src={require('./../assets/images/logo.png')} />
                </Link>

            </div>
            
            <PerfectScrollbar className="sidebar-items">
                <ul className="list-unstyled ps-0">
                    <li className="mb-1">
                        <Link tag="a" className="" to="/home">
                            <i className="fa fa-home"></i> Homepage
                        </Link>
                    </li>
                    {/* <li className="mb-1">
                        <Link tag="a" className="" to="/blank-page">
                            <i className="fa fa-file-o"></i> User Management
                        </Link>
                        <ul className="list-unstyled ps-1">
                            <li className="mb-2">
                                <Link tag="a" className="" to="/users">
                                    <i className="fa fa-bars"></i> Users
                                </Link>
                            </li>
                            <li className="mb-2">
                                <Link tag="a" className="" to="/groupusers">
                                    <i className="fa fa-bars"></i> User Group
                                </Link>
                            </li>
                            <li className="mb-2">
                                <Link tag="a" className="" to="/groupmenu">
                                    <i className="fa fa-bars"></i> Menu Akses
                                </Link>
                            </li>
                        </ul>
                    </li> */}
                    {menudata}
                    {/* <li className="border-top my-3"></li>
                    <li className="mb-1">
                        <Link tag="a" className="" to="/typography">
                        <i className="fa fa-text-width" aria-hidden="true"></i> Typography
                        </Link>
                    </li> */}
                    
                    {/* collapsable list item example */}
                    {/* <li className="mb-1">
                        <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#dashboard-collapse" aria-expanded="false">
                        Opportunity
                        </button>
                        <div className="collapse" id="dashboard-collapse">
                        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                            <li><a href="#" className="rounded">Overview</a></li>
                            <li><a href="#" className="rounded">Weekly</a></li>
                            <li><a href="#" className="rounded">Monthly</a></li>
                            <li><a href="#" className="rounded">Annually</a></li>
                        </ul>
                        </div>
                    </li> 
                    <li className="border-top my-3"></li> */}
                   
                </ul>
            </PerfectScrollbar>
            <div className="dropdown fixed-bottom-dropdown">
                <a href="#" className="d-flex align-items-center text-decoration-none dropdown-toggle" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="https://via.placeholder.com/50" alt="" width="32" height="32" className="rounded-circle me-2" />
                    <span>{this.state.NamaUser}</span>
                </a>
                <ul className="dropdown-menu dropdown-prof text-small shadow" aria-labelledby="dropdownUser2">
                    <li><Link className="dropdown-item" to="/change-password"><i className="fa fa-user-circle" aria-hidden="true"></i> Profile</Link></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><Link className="dropdown-item" onClick={this.LogOut}><i className="fa fa-sign-out" aria-hidden="true"></i> Sign out</Link></li>
                </ul>
            </div>
        </div>
    }
}

export default Sidebar;