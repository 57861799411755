import React from "react";
import "../../assets/css/login.css"
import { Link } from 'react-router-dom';
import authLayout from "../../hoc/authLayout";

class ChangedPassword extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            newpassword:'',
            confirmpassword:'',
            alertSucces: false,
            alermessage: '',
            bgalert: 'bg-primary',
            btnalert: 'btn-primary',
            typepass:'password',
            typepass2:'password'
        };
        this.showpass=this.showpass.bind(this);
        this.showpass2=this.showpass2.bind(this);
    }

    handleUpdate(event){
        event.preventDefault();
            const newpassword = this.state.newpassword;
            const confirmpassword = this.state.confirmpassword;
            const usToken = localStorage.getItem('UsToken'); 
            // const datsim = { username, email, password }
            // console.log(datsim);
            if(newpassword !== confirmpassword){
                // alert("New Password and Confirm Password Not Match");
                this.setState({
                    alertSucces : true,
                    bgalert: "bg-warning",
                    btnalert: "btn-warning"
                })  
                this.setState({
                    alertSucces : true,
                    alermessage: "New Password and Confirm Password Not Match"
                })  
            }else{
                fetch("https://admin.delpis.online/api/users/updatepass", {
                    method: "post",
                    body: JSON.stringify({
                        newpassword: newpassword,
                        token: usToken
                    }),
                    headers:{
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then((response) => {
                    // this.UserList();
                    // console.log(response['token']);
                    if(response['status'] === false){
                        // alert(response['message']);
                        this.setState({
                            alertSucces : true,
                            bgalert: "bg-danger",
                            btnalert: "btn-danger"
                        })  
                        this.setState({
                            alertSucces : true,
                            alermessage: response['message']
                        })  
                    }else{
                        // alert("Password Has Been Changed");
                        this.setState({
                            alertSucces : true,
                            bgalert: "bg-success",
                            btnalert: "btn-success"
                        })  
                        this.setState({
                            alertSucces : true,
                            alermessage: "Password Has Been Changed"
                        })  
                        window.location = "/";
                        // this.props.history.push("/home");
                        console.log("Request succeeded with  response");
                    }
                })
                .catch(function(error) {
                    // this.setState({isLogin : ""});
                    console.log("Request failed", error);
                });
            }
    }

    showpass(){
        if(this.state.typepass == "password"){
            this.setState({typepass : 'text'})
        }else{
            this.setState({typepass : 'password'})
        }
    }

    showpass2(){
        if(this.state.typepass2 == "password"){
            this.setState({typepass2 : 'text'})
        }else{
            this.setState({typepass2 : 'password'})
        }
    }

    render(){
        return <>
            <div className="reset-password-section text-center">
                  <h3><i className="fa fa-lock fa-4x"></i></h3>
                  <h2 className="text-center">Changed Password?</h2>
                  <p>Pleased changed your password here.</p>
                  <div className="panel-body">
    
                    <form id="register-form" role="form" autocomplete="off" className="form" method="post">
    
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">New Password</label>
                            <div className="input-group mb-3">
                                <input type={this.state.typepass} className="form-control" placeholder="New Password" aria-label="Recipient's username" aria-describedby="basic-addon2"
                                 onChange={(e) => this.setState({newpassword : e.target.value})}/>
                                 <span className="input-group-text" id="basic-addon2"><i className={`fa fa-eye ` + (this.state.typepass === 'password' ? `fa-eye-slash` : `fa-eye`)}
                                        onClick={this.showpass}></i></span>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Confirm New Password</label>
                            <div className="input-group mb-3">
                                <input type={this.state.typepass2} className="form-control" placeholder="Confirm New Password" aria-label="Recipient's username" aria-describedby="basic-addon2"
                                 onChange={(e) => this.setState({confirmpassword : e.target.value})}/>
                                <span className="input-group-text" id="basic-addon2"><i className={`fa fa-eye ` + (this.state.typepass2 === 'password' ? `fa-eye-slash` : `fa-eye`)}
                                        onClick={this.showpass2}></i></span>
                            </div>
                        </div>

                        <div className="form-group mt-2">
                            <button type="button" className="btn btn-primary btn-lg"
                                onClick={this.handleUpdate.bind(this)}>Update Password</button>
                        </div>
                      
                    </form>
    
                  </div>
                </div>

                {this.state.alertSucces && 
                <div className="ModalAlert">
                    <div className="alertNew">
                        <div className="modal-content">                   
                            <div className={`modal-header ${this.state.bgalert}`}>
                                <h5 className="modal-title" id="exampleModalLabel">Alert Message</h5>
                                <button type="button" className="btn-close" aria-label="Close"
                                    onClick={(e) => this.setState({alertSucces : false})}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div className="modal-body text-black">
                                <center><h1>{ this.state.alermessage }</h1></center>
                            </div>            
                            <div style={{width:"100%"}}>
                                <center>
                                <button className={`btn ${this.state.btnalert}`} onClick={(e) => this.setState({alertSucces : false})}>
                                    OK</button> 
                                </center>
                            </div>
                            <br></br>
                        </div>
                    </div>
                </div>
                }
        </>
    }
}

export default authLayout(ChangedPassword);