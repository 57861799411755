import React from "react";
import { Link } from 'react-router-dom';
import regisvendorLayout from "../RegisVendorLayout";
import ModalComponent from "../../../components/ModalComponent";

class PengalamanVendorPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            pengNama:'',
            pengBidang:'',
            pengLokasi:'',
            experience:[],
            id:'',
            alertSucces: false,
            alermessage: '',
            bgalert: 'bg-primary',
            btnalert: 'btn-primary',
            typepass:'password'
        };
        this.handleSave = this.handleSave.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

    }
    
    componentDidMount() {
        this.ExpVendor();
    }
      
    ExpVendor() {           
        const usToken = localStorage.getItem('UsToken'); 
        fetch("https://vendor.delpis.online/api/vendor/experience", {
            method: "post",
            body: JSON.stringify({
                token: usToken
            }),
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((response) => {
            this.setState({
                experience: response['experienceData'] || []
            });
            
            localStorage.setItem("ExperienceVendor", JSON.stringify(this.state.experience));
        })
    }

    adddata(){
        this.setState({
            pengNama:'',
            pengBidang:'',
            pengLokasi:'',
        })
    }
    
    viewedit(item){
        this.setState({
            pengNama: item.company_name || '',
            pengBidang: item.job_name || '',
            pengLokasi: item.location || '',
            id: item.company_name || '',
            editsts:1
        })
    }

    delete(Namapeng){
        this.setState({
            id : Namapeng
        })  
        // this.setState({groupuserdata})
    }

    handleDelete(event){
        const data = this.state.experience.filter(i => i.company_name !== this.state.id)
        this.state.experience = data;
        localStorage.setItem("ExperienceVendor", JSON.stringify(this.state.experience));
        this.setState({
            person : data,
            alertSucces : true,
            alermessage: "Deleted Succes",
            bgalert: "bg-danger",
            btnalert: "btn-danger"})
    }

    handleSave(event){
        event.preventDefault();
        this.setState({
            bgalert: "bg-warning",
            btnalert: "btn-warning"
        })

        if(this.state.pengNama == "" || this.state.pengNama == null){
            this.setState({
                alertSucces : true,
                alermessage: "Nama harus di isi"
            })  
            return;
        }

        if(this.state.pengBidang == "" || this.state.pengBidang == null){
            this.setState({
                alertSucces : true,
                alermessage: "Jabatan harus di isi"
            })  
            return;
        }

        if(this.state.pengLokasi == "" || this.state.pengLokasi == null){
            this.setState({
                alertSucces : true,
                alermessage: "No. KTP harus di isi"
            })  
            return;
        }
        
        if(this.state.editsts === 1){
            const newData = Object.values(this.state.experience);

            const index = newData.findIndex(item => item.company_name === this.state.id);
            console.log('index : ' + index);
            // Lakukan perubahan pada salinan array
            if (index !== -1) {
                newData[index] = { ...newData[index],
                    company_name: this.state.pengNama,
                    job_name: this.state.pengBidang,
                    location: this.state.pengLokasi
                };
                // console.log(newData);
                this.state.experience = newData; // Update state dengan array yang sudah diubah
                localStorage.setItem("ExperienceVendor", JSON.stringify(this.state.experience));
            }
        } else {
        
            const oldArray = Object.values(this.state.experience);
            var newStateArray = oldArray.slice();
            newStateArray.push({
                "company_name": this.state.pengNama,
                "job_name": this.state.pengBidang,
                "location": this.state.pengLokasi
                });
            this.state.experience = newStateArray;

            localStorage.setItem("ExperienceVendor", JSON.stringify(this.state.experience));
        }

        this.TombolElement.click();
    }
    
    modalFooterDeleted(){      

        return <>
            <div style={{width:"100%"}}>
                <button className="btn btn-success" onClick={this.handleDelete}
                    data-bs-dismiss="modal">Yes</button>
            &nbsp;
            <button className="btn btn-danger"
                data-bs-dismiss="modal">No</button>
            </div>
        </>;
    }

    modalDeleted(){
        return <>
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                <form>
                    <div className="mb-3">
                        <label htmlFor="group_name" className="form-label">Apakah anda yakin ingin menghapus data?</label>
                    </div>
                </form>
            </div>
        </>;
    }

    modalFooterContent(){
        return <>
            <div style={{width:"100%"}}>
                <button className="btn btn-success" onClick={this.handleSave}>Save</button> 
                &nbsp;
                <button className="btn btn-danger"  ref={tombol => this.TombolElement = tombol}
                    data-bs-dismiss="modal">Cancel</button> 
            </div>
        </>;
    }

    modalContent(){
        return <>
            {/* <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Group User Data</button>
                </li>
            </ul> */}
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                    <form>
                        <div className="mb-3">
                            <label htmlFor="pengNama" className="form-label">Nama Pekerjaan <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="pengNama" aria-describedby="Group_name"
                                onChange={(e) => this.setState({pengNama : e.target.value})}
                                value={ this.state.pengNama }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="pengBidang" className="form-label">Bidang Pekerjaan <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="pengBidang" aria-describedby="Group_name"
                                onChange={(e) => this.setState({pengBidang : e.target.value})}
                                value={ this.state.pengBidang }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="pengLokasi" className="form-label">Lokasi <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="pengLokasi" aria-describedby="Group_name"
                                onChange={(e) => this.setState({pengLokasi : e.target.value})}
                                value={ this.state.pengLokasi }/>
                        </div>
                    </form>
                </div>
            </div>
        </>;
    }

    render(){
        var no = 1;

        const VendorExperience = JSON.parse(localStorage.getItem('ExperienceVendor'));
        // const VendorContact = localStorage.getItem('ContactVendor');
        if(VendorExperience){
            this.state.experience = VendorExperience;
        }
        // console.log(this.state.contact);
        const ExperienceVendor = this.state.experience.map((item, i) => (

            <tr>
                <td>{ no++ }</td>
                <td>{ item.company_name }</td>
                <td>{ item.job_name }</td>  
                <td>{ item.location }</td>
                <td>
                    <div className="dropdown table-action-dropdown">
                        <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButtonSM" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v" aria-hidden="true"></i></button>
                        <ul className="dropdown-menu dropdown-prof" aria-labelledby="dropdownMenuButtonSM">
                            <li><a className="dropdown-item" href="#" onClick={this.viewedit.bind(this, item)}
                                data-bs-toggle="modal" data-bs-target="#exampleModalDefault">
                                <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Edit</a></li>
                            <div className="dropdown-divider"></div>        
                            <li><a className="dropdown-item text-danger" href="#" onClick={this.delete.bind(this, item.company_name)}
                                data-bs-toggle="modal" data-bs-target="#deletedModal">
                                <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete</a></li>                            
                        </ul>
                    </div>
                </td>
            </tr>
        ));

        return <>
            <div>
                <div className="row bg-primary text-white">
                    <center><h1>Pengalaman</h1></center>
                </div>
                <div className="row">
                    &nbsp;
                </div>
                <div className="row">
                    <div className="col-6">
                        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalDefault"
                            onClick={this.adddata.bind(this)}>
                            Add
                        </button>
                    </div>
                </div>
                
                <div className="row">
                    &nbsp;
                </div>

                <div className="row">                    
                    <table className="table">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th className="Col-klik">Nama Pekerjaan</th>
                                <th className="Col-klik">Bidang Pekerjaan</th>
                                <th className="Col-klik">Lokasi</th>
                                {/* <th>Status</th> */}
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { ExperienceVendor }
                        </tbody>
                    </table>
                </div>

                <div className="text-right text-lg-start mt-4 pt-2">
                    <Link to="/RegisVendorAttach" type="button" className="btn btn-primary btn-lg">Next</Link>
                </div>
            </div>

            <ModalComponent title="Deleted Confirmation" footerContent={this.modalFooterDeleted()} content={this.modalDeleted()} dataBsBackdrop="static" id="deletedModal"/>
            <ModalComponent title="Add Pengalaman" footerContent={this.modalFooterContent()} content={this.modalContent()} dataBsBackdrop="static" id="exampleModalDefault"/>
                
            {this.state.alertSucces && 
                <div className="ModalAlert">
                    <div className="alertNew">
                        <div className="modal-content">                   
                            <div className={`modal-header ${this.state.bgalert}`}>
                                <h5 className="modal-title" id="exampleModalLabel">Alert Message</h5>
                                <button type="button" className="btn-close" aria-label="Close"
                                    onClick={(e) => this.setState({alertSucces : false})}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div className="modal-body text-black">
                                <center><h1>{ this.state.alermessage }</h1></center>
                            </div>            
                            <div style={{width:"100%"}}>
                                <center>
                                <button className={`btn ${this.state.btnalert}`} onClick={(e) => this.setState({alertSucces : false})}>
                                    OK</button> 
                                </center>
                            </div>
                            <br></br>
                        </div>
                    </div>
                </div>
                }
        </>
    }
}

export default regisvendorLayout(PengalamanVendorPage);