import React from "react";
import "../../assets/css/login.css"
import { Link } from 'react-router-dom';
import authLayout from "../../hoc/authLayout";

class ResetPassword extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            email:'',
            alertSucces: false,
            alermessage: '',
            bgalert: 'bg-primary',
            btnalert: 'btn-primary'
        };
    }



    handleReset(event){
      event.preventDefault();
          const username = this.state.username;
          const password = this.state.password;
          // const datsim = { username, email, password }
          // console.log(datsim);
      fetch("https://admin.delpis.online/api/users/forgotpass", {
          method: "post",
          body: JSON.stringify({
              email: this.state.email 
          }),
          headers:{
              'Content-Type': 'application/json'
          }
      }).then(response => response.json())
      .then((response) => {
          // this.UserList();
          // console.log(response['token']);
          if(response['status'] === false){
            //   alert(response['message']);
              this.setState({
                alertSucces : true,
                bgalert: "bg-danger",
                btnalert: "btn-danger"
            })  
            this.setState({
                alertSucces : true,
                alermessage: response['message']
            })
          }else{
            //   alert('Please Check Your Email');
            this.setState({
                alertSucces : true,
                bgalert: "bg-success",
                btnalert: "btn-success"
            })  
            this.setState({
                alertSucces : true,
                alermessage: "Please Check Your Email"
            })
              localStorage.setItem('norut', response['norut']);
              window.location = "/";
          }
      })
      .catch(function(error) {
          // this.setState({isLogin : ""});
          console.log("Request failed", error);
      });
  }

  render(){
      return <>
          <div className="reset-password-section text-center">
                <h3><i className="fa fa-lock fa-4x"></i></h3>
                <h2 className="text-center">Forgot Password?</h2>
                <p>You can reset your password here.</p>
                <div className="panel-body">
  
                  <form id="register-form" role="form" autocomplete="off" className="form" method="post">
  
                    <div className="form-group">
                      <span className="input-group-addon"><i className="glyphicon glyphicon-envelope color-blue"></i></span>
                          <input id="email" name="email" placeholder="email address"
                            className="form-control form-control-lg"  type="email"
                            onChange={(e) => this.setState({email : e.target.value})} />
                    </div>

                      <div className="form-group mt-2">
                          <button type="button" className="btn btn-primary btn-lg"
                            onClick={this.handleReset.bind(this)}>Reset Password</button>
                          <p className="small fw-bold mt-2 pt-1 mb-0">Remember your password ? <Link to="/"
                              className="link-danger">Login</Link></p>
                      </div>
                    
                  </form>
  
                </div>
              </div>

              {this.state.alertSucces && 
                <div className="ModalAlert">
                    <div className="alertNew">
                        <div className="modal-content">                   
                            <div className={`modal-header ${this.state.bgalert}`}>
                                <h5 className="modal-title" id="exampleModalLabel">Alert Message</h5>
                                <button type="button" className="btn-close" aria-label="Close"
                                    onClick={(e) => this.setState({alertSucces : false})}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div className="modal-body text-black">
                                <center><h1>{ this.state.alermessage }</h1></center>
                            </div>            
                            <div style={{width:"100%"}}>
                                <center>
                                <button className={`btn ${this.state.btnalert}`} onClick={(e) => this.setState({alertSucces : false})}>
                                    OK</button> 
                                </center>
                            </div>
                            <br></br>
                        </div>
                    </div>
                </div>
                }
      </>
  }
}

export default authLayout(ResetPassword);