import React from "react";
import adminLayout from "../hoc/adminLayout"
import "./../assets/css/profile.css"
import { NavLink } from "react-router-dom";

const userProfileLayout = (ChildComponent) => {
    class UserProfilePageHoc extends React.Component {
        constructor(props){
            super(props);
    
            this.state = {
                NamaUser:'',
                groupname:''
            }
        }

        componentDidMount() {
            const usData = JSON.parse(localStorage.getItem('UsData'));
            var firstname = "";
            var lastname = "";

            if(usData['firstname']){
                firstname = usData['firstname'];
            }

            if(usData['lastname']){
                lastname = usData['lastname'];
            }

            this.setState({
                NamaUser : firstname+' '+lastname,
                groupname: usData['group_name']
            })
        }
        render(){
            return <>
                <div className="container">
                <div className="row profile">
                    <div className="col-md-3">
                            <div className="profile-sidebar">
                                <div className="my-3 p-3 bg-body rounded shadow-sm">

                                {/* <!-- SIDEBAR USERPIC --> */}
                            {/* <div className="profile-userpic">
                                <img src="https://via.placeholder.com/150" className="img-responsive profile-img-center" alt="" />
                            </div> */}
                            {/* <!-- END SIDEBAR USERPIC -->
                            <!-- SIDEBAR USER TITLE --> */}
                            <div className="profile-usertitle">
                                <div className="profile-usertitle-name">
                                    {this.state.NamaUser}
                                </div>
                                <div className="profile-usertitle-job">
                                    {this.state.groupname}
                                </div>
                            </div>
                            {/* <!-- END SIDEBAR USER TITLE -->
                            <!-- SIDEBAR BUTTONS --> */}
                            <hr/>                
                            <div>
                                <div className="bd-example">
                                <div className="list-group">
                                    {/* <NavLink  to="/profile" className={({ isActive }) => `list-group-item list-group-item-action ${isActive ? 'active': ''}`}>Personal Info</NavLink> */}
                                    {/* <NavLink to="/change-password" className={({ isActive }) => `list-group-item list-group-item-action ${isActive ? 'active': ''}`}>Change Password</NavLink> */}
                                    {/* <NavLink to="/preferences" className={({ isActive }) => `list-group-item list-group-item-action ${isActive ? 'active': ''}`}>Preferences</NavLink> */}
                                </div>
                            </div>
                                </div>
                            </div>
                            
                            
                            </div>
                            </div>
                            <div className="col-md-9">
                                <div className="profile-content">
                                    <ChildComponent {...this.props} />
                                </div>
                            </div>
                        </div>
                    </div>
            </>
        }
    }

    return adminLayout(UserProfilePageHoc);
}


export default userProfileLayout;