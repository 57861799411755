import React from "react";
import { Link } from 'react-router-dom';
import regisvendorLayout from "../RegisVendorLayout";
import ModalComponent from "../../../components/ModalComponent";

class AhliVendorPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            ahliName:'',
            ahliTglLahir:'',
            ahliKTP:'',
            ahliNPWP:'',
            ahliPendidikan:'',
            ahliPengalaman:'',
            ahli:[],
            id:'',
            alertSucces: false,
            alermessage: '',
            bgalert: 'bg-primary',
            btnalert: 'btn-primary',
            typepass:'password'
        };
        this.handleSave = this.handleSave.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.onNPWP = this.onNPWP.bind(this);
        this.onKTP = this.onKTP.bind(this);
    }
    
    componentDidMount() {
        this.AhliVendor();
    }
      
    AhliVendor() {           
        const usToken = localStorage.getItem('UsToken'); 
        fetch("https://vendor.delpis.online/api/vendor/ahli", {
            method: "post",
            body: JSON.stringify({
                token: usToken
            }),
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((response) => {
            this.setState({
                ahli: response['ahliData'] || []
            });
            
            localStorage.setItem("AhliVendor", JSON.stringify(this.state.ahli));
        })
    }

    adddata(){
        this.setState({
            ahliName:'',
            ahliTgllahir:'',
            ahliKTP:'',
            ahliNPWP:'',
            ahliPendidikan:'',
            ahliPengalaman:'',
        })
    }
    
    viewedit(item){
        this.setState({
            ahliName: item.fullname || '',
            ahliTglLahir: item.dob || '',
            ahliKTP: item.idcard || '',
            ahliNPWP: item.npwp || '',
            ahliPendidikan: item.education || '',
            ahliPengalaman: item.experience || '',
            id: item.idcard || '',
            editsts:1
        })
    }

    delete(KTPahli){
        this.setState({
            id : KTPahli
        })  
        // this.setState({groupuserdata})
    }

    handleDelete(event){
        const data = this.state.ahli.filter(i => i.idcard !== this.state.id)
        this.state.ahli = data;
        localStorage.setItem("ExpertVendor", JSON.stringify(this.state.ahli));
        this.setState({
            person : data,
            alertSucces : true,
            alermessage: "Deleted Succes",
            bgalert: "bg-danger",
            btnalert: "btn-danger"})
    }

    handleSave(event){
        event.preventDefault();
        this.setState({
            bgalert: "bg-warning",
            btnalert: "btn-warning"
        })

        if(this.state.ahliName == "" || this.state.ahliName == null){
            this.setState({
                alertSucces : true,
                alermessage: "Nama harus di isi"
            })  
            return;
        }

        if(this.state.ahliTglLahir == "" || this.state.ahliTglLahir == null){
            this.setState({
                alertSucces : true,
                alermessage: "Tgl Lahir harus di isi"
            })  
            return;
        }

        if(this.state.ahliKTP == "" || this.state.ahliKTP == null){
            this.setState({
                alertSucces : true,
                alermessage: "No. KTP harus di isi"
            })  
            return;
        }

        if(this.state.ahliNPWP == "" || this.state.ahliNPWP == null){
            this.setState({
                alertSucces : true,
                alermessage: "No. NPWP harus di isi"
            })  
            return;
        }

        if(this.state.ahliPendidikan == "" || this.state.ahliPendidikan == null){
            this.setState({
                alertSucces : true,
                alermessage: "Pendidikan harus di isi"
            })  
            return;
        }

        if(this.state.ahliPengalaman == "" || this.state.ahliPengalaman == null){
            this.setState({
                alertSucces : true,
                alermessage: "Pengalaman harus di isi"
            })  
            return;
        }
        
        if(this.state.editsts === 1){
            const newData = Object.values(this.state.ahli);

            const index = newData.findIndex(item => item.idcard === this.state.id);
            console.log('index : ' + index);
            // Lakukan perubahan pada salinan array
            if (index !== -1) {
                newData[index] = { ...newData[index],
                    fullname: this.state.ahliName,
                    dob: this.state.ahliTglLahir,
                    idcard: this.state.ahliKTP,
                    npwp: this.state.ahliNPWP,
                    education: this.state.ahliPendidikan,
                    experience: this.state.ahliPengalaman
                };
                // console.log(newData);
                this.state.ahli = newData; // Update state dengan array yang sudah diubah
                localStorage.setItem("ExpertVendor", JSON.stringify(this.state.ahli));
            }
        } else {
        
            const oldArray = Object.values(this.state.ahli);
            var newStateArray = oldArray.slice();
            newStateArray.push({
                "fullname": this.state.ahliName,
                "dob": this.state.ahliTglLahir,
                "idcard": this.state.ahliKTP,
                "npwp": this.state.ahliNPWP,
                "education": this.state.ahliPendidikan,
                "experience": this.state.ahliPengalaman
                });
            this.state.ahli = newStateArray;

            localStorage.setItem("ExpertVendor", JSON.stringify(this.state.ahli));
        }
        this.TombolElement.click();
    }
   
    onKTP(e){
       const re = /^[0-9\b]+$/;
       if (e.target.value === '' || re.test(e.target.value)) {
          this.setState({ahliKTP: e.target.value})
       }
    }
   
    onNPWP(e){
       const re = /^[0-9\b]+$/;
       if (e.target.value === '' || re.test(e.target.value)) {
          this.setState({ahliNPWP: e.target.value})
       }
    }
    
    modalFooterDeleted(){      

        return <>
            <div style={{width:"100%"}}>
                <button className="btn btn-success" onClick={this.handleDelete}
                    data-bs-dismiss="modal">Yes</button>
            &nbsp;
            <button className="btn btn-danger"
                data-bs-dismiss="modal">No</button>
            </div>
        </>;
    }

    modalDeleted(){
        return <>
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                <form>
                    <div className="mb-3">
                        <label htmlFor="group_name" className="form-label">Apakah anda yakin ingin menghapus data?</label>
                    </div>
                </form>
            </div>
        </>;
    }

    modalFooterContent(){
        return <>
            <div style={{width:"100%"}}>
                <button className="btn btn-success" onClick={this.handleSave}>Save</button> 
                &nbsp;
                <button className="btn btn-danger" ref={tombol => this.TombolElement = tombol}
                    data-bs-dismiss="modal">Cancel</button> 
            </div>
        </>;
    }

    modalContent(){
        return <>
            {/* <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Group User Data</button>
                </li>
            </ul> */}
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                    <form>
                        <div className="mb-3">
                            <label htmlFor="ahliName" className="form-label">Nama <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="ahliName" aria-describedby="Group_name"
                                onChange={(e) => this.setState({ahliName : e.target.value})}
                                value={ this.state.ahliName }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="ahliTglLahir" className="form-label">Tanggal Lahir <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="date" className="form-control" id="ahliTglLahir" aria-describedby="Group_name"
                                onChange={(e) => this.setState({ahliTglLahir : e.target.value})}
                                value={ this.state.ahliTglLahir }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="ahliKTP" className="form-label">No. KTP <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="ahliKTP" aria-describedby="Group_name"
                                onChange={this.onKTP} maxLength={16}
                                value={ this.state.ahliKTP }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="ahliNPWP" className="form-label">NPWP <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="ahliNPWP" aria-describedby="Group_name"
                                onChange={this.onNPWP} maxLength={16}
                                value={ this.state.ahliNPWP }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="ahliPendidikan" className="form-label">Pendidikan <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="ahliPendidikan" aria-describedby="Group_name"
                                onChange={(e) => this.setState({ahliPendidikan : e.target.value})}
                                value={ this.state.ahliPendidikan }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="ahliPengalaman" className="form-label">Pengalaman <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="ahliPengalaman" aria-describedby="Group_name"
                                onChange={(e) => this.setState({ahliPengalaman : e.target.value})}
                                value={ this.state.group_name }/>
                        </div>
                    </form>
                </div>
            </div>
        </>;
    }

    render(){
        var no = 1;

        const VendorExpert = JSON.parse(localStorage.getItem('ExpertVendor'));
        // const VendorContact = localStorage.getItem('ContactVendor');
        if(VendorExpert){
            this.state.ahli = VendorExpert;
        }
        // console.log(this.state.contact);
        const ExpertVendor = this.state.ahli.map((item, i) => (

            <tr>
                <td>{ no++ }</td>
                <td>{ item.fullname }</td>
                <td>{ item.dob }</td>  
                <td>{ item.idcard }</td>   
                <td>{ item.npwp }</td> 
                <td>{ item.education }</td> 
                <td>{ item.experience }</td>      
                <td>
                    <div className="dropdown table-action-dropdown">
                        <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButtonSM" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v" aria-hidden="true"></i></button>
                        <ul className="dropdown-menu dropdown-prof" aria-labelledby="dropdownMenuButtonSM">
                            <li><a className="dropdown-item" href="#" onClick={this.viewedit.bind(this, item)}
                                data-bs-toggle="modal" data-bs-target="#exampleModalDefault">
                                <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Edit</a></li>
                            <div className="dropdown-divider"></div>        
                            <li><a className="dropdown-item text-danger" href="#" onClick={this.delete.bind(this, item.idcard)}
                                data-bs-toggle="modal" data-bs-target="#deletedModal">
                                <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete</a></li>                            
                        </ul>
                    </div>
                </td>
            </tr>
        ));
        return <>
            <div>
                <div className="row bg-primary text-white">
                    <center><h1>Tenaga Ahli</h1></center>
                </div>
                <div className="row">
                    &nbsp;
                </div>
                <div className="row">
                    <div className="col-6">
                        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalDefault"
                            onClick={this.adddata.bind(this)}>
                            Add
                        </button>
                    </div>
                </div>
                <div className="row">
                    &nbsp;
                </div>

                <div className="row">                    
                    <table className="table">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th className="Col-klik">Nama</th>
                                <th className="Col-klik">Tanggal Lahir</th>
                                <th className="Col-klik">No. KTP</th>
                                <th className="Col-klik">NPWP</th>
                                <th className="Col-klik">Pendidikan</th>
                                <th className="Col-klik">Pengalaman</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { ExpertVendor }
                        </tbody>
                    </table>
                </div>

                <div className="text-right text-lg-start mt-4 pt-2">
                    <Link to="/RegisVendorPengalaman" type="button" className="btn btn-primary btn-lg">Next</Link>
                </div>
            </div>
                
            <ModalComponent title="Deleted Confirmation" footerContent={this.modalFooterDeleted()} content={this.modalDeleted()} dataBsBackdrop="static" id="deletedModal"/>
            <ModalComponent title="Add Tenaga Ahli" footerContent={this.modalFooterContent()} content={this.modalContent()} dataBsBackdrop="static" id="exampleModalDefault"/>
                

            {this.state.alertSucces && 
                <div className="ModalAlert">
                    <div className="alertNew">
                        <div className="modal-content">                   
                            <div className={`modal-header ${this.state.bgalert}`}>
                                <h5 className="modal-title" id="exampleModalLabel">Alert Message</h5>
                                <button type="button" className="btn-close" aria-label="Close"
                                    onClick={(e) => this.setState({alertSucces : false})}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div className="modal-body text-black">
                                <center><h1>{ this.state.alermessage }</h1></center>
                            </div>            
                            <div style={{width:"100%"}}>
                                <center>
                                <button className={`btn ${this.state.btnalert}`} onClick={(e) => this.setState({alertSucces : false})}>
                                    OK</button> 
                                </center>
                            </div>
                            <br></br>
                        </div>
                    </div>
                </div>
                }
        </>
    }
}

export default regisvendorLayout(AhliVendorPage);