import React from "react";
import adminLayout from "../hoc/adminLayout";
// import ModalComponent from "../components/ModalComponent";
class RolesPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {person: []};
    }

    componentDidMount() {
        // this.UserList();
        fetch('https://103.127.137.83/b2b-app/api/auth/view')
        .then((response) => response.json())
        .then((data) => this.setState({person : data['data']}))
    }

    render(){
        
        const persons = this.state.person.map((item, i) => (
            <tr>
                <td>{ i }</td>
                <td>{ item.user_name }</td>
                <td>{ item.name }</td>
                <td>{ item.email }</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>{ item.status }</td>
                <td>
                    <div className="dropdown table-action-dropdown">
                        <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButtonSM" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v" aria-hidden="true"></i></button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButtonSM">
                            <li><a className="dropdown-item" href="#"><i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Edit</a></li>
                            <div className="dropdown-divider"></div>
                            <li><a className="dropdown-item text-danger" href="#"><i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete</a></li>
                        </ul>
                    </div>
                </td>
            </tr>
        ));
        
        return (
            <>
            
            <div>
                <div className="bd-example">
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">User Management</a></li>
                    <li className="breadcrumb-item"><a href="#">Users</a></li>
                    <li className="breadcrumb-item"><a href="#">Add</a></li>
                </ol>
                </nav>
                </div>
            </div>
            <div className="table-container">
                <div className="row">
                    <div className="col-12">
                        <button className="btn btn-primary" type="submit" to="/users_add">Add User</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        &nbsp;
                    </div>
                </div>
                <div className="d-flex text-muted">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Username</th>
                                <th>Full Name</th>
                                <th>Email</th>
                                <th>Customer Code</th>
                                <th>Role Level</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { persons }
                        </tbody>
                    </table>
                </div>
                <nav className="table-bottom-center-pagination" aria-label="Page navigation example ">
                    <ul className="pagination">
                        <li className="page-item">
                        <a className="page-link" href="#" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                            <span className="sr-only">Previous</span>
                        </a>
                        </li>
                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                        <li className="page-item">
                        <a className="page-link" href="#" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                            <span className="sr-only">Next</span>
                        </a>
                        </li>
                    </ul>
                </nav>
            </div>
            </>
          );
    }
}

export default adminLayout(RolesPage);