import React from "react";
import PropTypes from 'prop-types';

class AgreementComponent extends React.Component {
    constructor(props){
        super(props);
        this.state = {}
    }

    renderFooter(){
        if(this.props.footerContent){
            return <>
                <div className="modal-footer">
                    {this.props.footerContent}
                </div>
            </>;
        }
    }

    render(){
        return <>
            <div className={`modal fade ${this.props.className}`} id={this.props.id} tabIndex="-1" aria-labelledby={this.props.exampleModalLabel} data-bs-backdrop={this.props.dataBsBackdrop} aria-hidden={this.props.ariaHidden} style={{display: "none"}}>
                <div className={`${this.props.fullScreen ? 'modal-fullscreen': ''} modal-dialog modal-dialog-scrollable`}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{this.props.title}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><i className="fa fa-times" aria-hidden="true"></i></button>
                        </div>
                        <div className="modal-body">
                            <p className="text-center">
                                <strong>PENDAFTARAN ONLINE REKANAN E-PROCUREMENT</strong>
                            </p>
                            <p>
                                Setiap Penyedia Barang dan Jasa pengguna aplikasi E-Procurement, diatur oleh ketentuan sebagai berikut.
                            </p>
                            <p>I. KETENTUAN UMUM</p>
                            <ol>
                                <li>
                                    Rekanan wajib tunduk pada persyaratan yang tertera dalam ketentuan ini serta kebijakan lain yang ditetapkan oleh sebagai pengelola situs
                                </li>
                                <li>
                                    Transaksi melalui Sistem Online Procurement hanya boleh dilakukan/diikuti oleh Rekanan yang sudah terdaftar dan teraktivasi untuk bisa mengikuti transaksi secara elektronik.
                                </li>
                                <li>
                                    Transaksi melalui Sistem Online Procurement hanya boleh dilakukan/diikuti oleh Rekanan yang sudah terdaftar dan teraktivasi untuk bisa mengikuti transaksi secara elektronik.
                                </li>
                            </ol>
                            <p>II. PERSYARATAN KEANGGOTAAN E-PROCUREMENT</p>
                            <ol>
                                <li>
                                    Rekanan harus berbentuk badan usaha atau perseorangan dan dianggap mampu melakukan perbuatan hukum.
                                </li>
                                <li>
                                    Untuk mendapatkan akun dalam Sistem Online Procurement, calon Rekanan terlebih dahulu harus melakukan registrasi online dengan data yang benar dan akurat, sesuai dengan keadaan yang sebenarnya.
                                </li>
                                <li>
                                    Calon Rekanan dapat melakukan transaksi melalui Sistem Online Procurement, apabila telah menerima konfirmasi aktivasi keanggotaannya dari Sistem Online Procurement.
                                </li>
                                <li>
                                    Rekanan wajib memperbaharui data perusahaannya jika tidak sesuai lagi dengan keadaan yang sebenarnya atau jika tidak sesuai dengan ketentuan ini.
                                </li>
                                <li>
                                    Akun dalam Sistem Online Procurement akan berakhir apabila:
                                    <ul>
                                        <li>
                                            Rekanan mengundurkan diri dengan cara mengirimkan email atau surat kepada sebagai pengelola situs dan mendapatkan email atau surat konfirmasi atas pengunduran dirinya.
                                        </li>
                                        <li>
                                            Melanggar ketentuan yang telah ditetapkan oleh sebagai pengelola situs dan mendapatkan email atau surat konfirmasi atas pengunduran dirinya.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Rekanan setuju bahwa transaksi melalui Sistem Online Procurement tidak boleh menyalahi peraturan perundangan maupun etika bisnis yang berlaku di Indonesia.
                                </li>
                                <li>
                                    Rekanan tunduk pada semua peraturan yang berlaku di Indonesia yang berhubungan dengan, tetapi tidak terbatas pada, penggunaan jaringan yang terhubung pada jasa dan transmisi data teknis, baik di wilayah Indonesia maupun ke luar dari wilayah Indonesia melalui Sistem Online Procurement yang sesuai Undang-Undang Republik Indonesia Nomor 11, Tahun 2008, Tentang Informasi dan Transaksi Elektronik (UU ITE).
                                </li>
                                <li>
                                    Rekanan menyadari bahwa usaha apapun untuk dapat menembus sistem komputer dengan tujuan memanipulasi Sistem Online Procurement merupakan tindakan melanggar hukum.
                                </li>
                                <li>
                                     sebagai pengelola situs berhak memutuskan perjanjian dengan Rekanan secara sepihak apabila Rekanan dianggap tidak dapat menaati ketentuan yang ada. 
                                </li>
                            </ol>
                            <p>III. TANGGUNG JAWAB PENYEDIA BARANG DAN JASA</p>
                            <ol>
                                <li>
                                    Rekanan bertanggung jawab atas penjagaan kerahasiaan passwordnya dan bertanggung jawab atas transaksi dan kegiatan lain yang menggunakan akun miliknya.
                                </li>
                                <li>
                                    Rekanan setuju untuk segera memberitahukan kepada sebagai pengelola situs apabila mengetahui adanya penyalahgunaan akun miliknya oleh pihak lain yang tidak berhak dan/atau jika ada gangguan keamanan atas akun miliknya itu.
                                </li>
                            </ol>
                            <p>IV. PERUBAHAN KETENTUAN</p>
                            <p>sebagai pengelola situs dapat memperbaiki, menambah, atau mengurangi ketentuan ini setiap saat, dengan atau tanpa pemberitahuan sebelumnya. Setiap Rekanan terikat dan tunduk kepada ketentuan yang telah diperbaiki/ditambah/dikurangi.</p>
                        
                            {this.renderFooter()}
                        </div>
                    </div>
                </div>
            </div>
        </>;
    }
}

AgreementComponent.propTypes = {
    title: PropTypes.string.isRequired
};

export default AgreementComponent;