import React from "react";
import { Link } from 'react-router-dom';
import regisvendorLayout from "../RegisVendorLayout";
import ModalComponent from "../../../components/ModalComponent";

class ProfilVendorPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            compName:'',
            compNPWP:'',
            compAddr:'',
            compCity:'',
            compPostcode:'',
            compProv:'',
            compFax:'',
            compPhone:'',
            contPhone:'',
            contName:'',
            contPosisi:'',
            contNPWP:'',
            contEmail:'',
            alertSucces: false,
            alermessage: '',
            bgalert: 'bg-primary',
            btnalert: 'btn-primary',
            typepass:'password'
        };
        this.showpass=this.showpass.bind(this);
    }

    adddata(){
        this.setState({
            contPhone:'',
            contName:'',
            contPosisi:'',
            contNPWP:'',
            contEmail:'',
        })
    }
    
    modalFooterDeleted(){      

        return <>
            <div style={{width:"100%"}}>
                <button className="btn btn-success" onClick={this.handleDelete}
                    data-bs-dismiss="modal">Yes</button>
            &nbsp;
            <button className="btn btn-danger"
                data-bs-dismiss="modal">No</button>
            </div>
        </>;
    }

    modalDeleted(){
        return <>
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                <form>
                    <div className="mb-3">
                        <label htmlFor="group_name" className="form-label">Apakah anda yakin ingin menghapus data?</label>
                    </div>
                </form>
            </div>
        </>;
    }

    modalFooterContent(){
        return <>
            <div style={{width:"100%"}}>
                <button className="btn btn-success" onClick={this.handleSave}>Save</button> 
                &nbsp;
                <button className="btn btn-danger" ref={tombol => this.TombolElement = tombol}
                    data-bs-dismiss="modal">Cancel</button> 
            </div>
        </>;
    }

    modalContent(){
        return <>
            {/* <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Group User Data</button>
                </li>
            </ul> */}
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                    <form>
                        <div className="mb-3">
                            <label htmlFor="ContactName" className="form-label">Nama Contact <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="ContactName" aria-describedby="Group_name"
                                onChange={(e) => this.setState({contName : e.target.value})}
                                value={ this.state.group_name }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="ContactPhone" className="form-label">No. Handphone <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="ContactPhone" aria-describedby="Group_name"
                                onChange={(e) => this.setState({contPhone : e.target.value})}
                                value={ this.state.group_name }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="ContactEmail" className="form-label">Email Contact <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="ContactEmail" aria-describedby="Group_name"
                                onChange={(e) => this.setState({contEmail : e.target.value})}
                                value={ this.state.group_name }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="ContactPosisi" className="form-label">Posisi Contact <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="ContactPosisi" aria-describedby="Group_name"
                                onChange={(e) => this.setState({contPosisi : e.target.value})}
                                value={ this.state.group_name }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="ContactNPWP" className="form-label">NPWP Contact <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="ContactNPWP" aria-describedby="Group_name"
                                onChange={(e) => this.setState({contNPWP : e.target.value})}
                                value={ this.state.group_name }/>
                        </div>
                    </form>
                </div>
            </div>
        </>;
    }

    handleRegisNext(event){
        event.preventDefault();
            const username = this.state.username;
            const password = this.state.password;
           
            this.setState({
                bgalert: "bg-warning",
                btnalert: "btn-warning"
            })  

        fetch("https://vendor.delpis.online/api/auth/login", {
            method: "post",
            body: JSON.stringify({
                user_name: username, 
                password: password, 
            }),
            headers:{
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
        .then((response) => {
            // this.UserList();
            // console.log(response['token']);
            if(response['status'] === false){
                // alert(response['message']);

                this.setState({
                    bgalert: "bg-danger",
                    btnalert: "btn-danger"
                })    
                this.setState({
                    alertSucces : true,
                    alermessage: response['message']
                })  

            }else{
                if(username === password){
                    localStorage.setItem('UsToken', response['token']);
                    window.location = "/changepassword";
                }else{
                    localStorage.setItem('UsToken', response['token']);
                    window.location = "/home";
                    // this.props.history.push("/home");
                    console.log("Request succeeded with  response");
                }
            }
        })
        .catch(function(error) {
            // this.setState({isLogin : ""});
            console.log("Request failed", error);
        });
    }

    showpass(){
        if(this.state.typepass == "password"){
            this.setState({typepass : 'text'})
        }else{
            this.setState({typepass : 'password'})
        }
    }
    render(){

        return <>
            <form>
                <div className="row bg-primary text-white">
                    <center><h1>Informasi Umum</h1></center>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="CompanyName" className="form-label">Nama Perusahaan <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="CompanyName" aria-describedby="Username"
                                onChange={(e) => this.setState({compName : e.target.value})}
                                value={ this.state.username }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="CompanyNPWP" className="form-label">NPWP Perusahaan <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="CompanyNPWP" aria-describedby="Username"
                                onChange={(e) => this.setState({compNPWP : e.target.value})}
                                value={ this.state.username }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="CompanyStatus" className="form-label">Status <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <select className="form-select is-invalid" id="CompanyStatus" required=""
                                onChange={(e) => this.setState({group_users : e.target.value})}>
                                <option disabled="" value="0">Choose...</option>
                                <option disabled="" value="Pusat">Pusat</option>
                                <option disabled="" value="Cabang">Cabang</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="CompanyAddr" className="form-label">Alamat Perusahaan <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="CompanyAddr" aria-describedby="Username"
                                onChange={(e) => this.setState({compAddr : e.target.value})}
                                value={ this.state.username }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="CompanyCity" className="form-label">Kota <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="CompanyCity" aria-describedby="Username"
                                onChange={(e) => this.setState({compCity : e.target.value})}
                                value={ this.state.username }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="CompanyProv" className="form-label">Provinsi <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="CompanyProv" aria-describedby="Username"
                                onChange={(e) => this.setState({compProv : e.target.value})}
                                value={ this.state.username }/>
                        </div>

                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="CompanyPostCode" className="form-label">Kode Pos <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="CompanyPostCode" aria-describedby="Username"
                                onChange={(e) => this.setState({compPostcode : e.target.value})}
                                value={ this.state.username }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="CompanyPhone" className="form-label">Nomor Telpon Perusahaan <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="CompanyPhone" aria-describedby="Username"
                                onChange={(e) => this.setState({compPhone : e.target.value})}
                                value={ this.state.username }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="CompanyFax" className="form-label">Nomor Fax Perusahaan <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="CompanyFax" aria-describedby="Username"
                                onChange={(e) => this.setState({compFax : e.target.value})}
                                value={ this.state.username }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="CompanyEmail" className="form-label">Email Perusahaan <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="CompanyEmail" aria-describedby="Username"
                                onChange={(e) => this.setState({compEmail : e.target.value})}
                                value={ this.state.username }/>
                        </div>

                    </div>
                </div>
                <div className="row bg-primary text-white">
                    <center><h1>Contact Person</h1></center>
                </div>
                <div className="row">
                    &nbsp;
                </div>
                <div className="row">
                    <div className="col-6">
                        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalDefault"
                            onClick={this.adddata.bind(this)}>
                            Add Contact
                        </button>
                    </div>
                </div>
                
                <ModalComponent title="Deleted Confirmation" footerContent={this.modalFooterDeleted()} content={this.modalDeleted()} dataBsBackdrop="static" id="deletedModal"/>
                <ModalComponent title="Add Group Users" footerContent={this.modalFooterContent()} content={this.modalContent()} dataBsBackdrop="static" id="exampleModalDefault"/>
                
                <div className="row">
                    &nbsp;
                </div>

                <div className="row">                    
                    <table className="table">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th className="Col-klik">Nama</th>
                                <th className="Col-klik">No. Handphone</th>
                                <th className="Col-klik">Email</th>
                                <th className="Col-klik">Posisi</th>
                                <th className="Col-klik">Npwp</th>
                                {/* <th>Status</th> */}
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>

                <div className="text-right text-lg-start mt-4 pt-2">
                    <Link to="#" type="button" className="btn btn-primary btn-lg"
                     onClick={this.handleRegisNext.bind(this)} >Next</Link>
                </div>
            </form>

            {this.state.alertSucces && 
                <div className="ModalAlert">
                    <div className="alertNew">
                        <div className="modal-content">                   
                            <div className={`modal-header ${this.state.bgalert}`}>
                                <h5 className="modal-title" id="exampleModalLabel">Alert Message</h5>
                                <button type="button" className="btn-close" aria-label="Close"
                                    onClick={(e) => this.setState({alertSucces : false})}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div className="modal-body text-black">
                                <center><h1>{ this.state.alermessage }</h1></center>
                            </div>            
                            <div style={{width:"100%"}}>
                                <center>
                                <button className={`btn ${this.state.btnalert}`} onClick={(e) => this.setState({alertSucces : false})}>
                                    OK</button> 
                                </center>
                            </div>
                            <br></br>
                        </div>
                    </div>
                </div>
                }
        </>
    }
}

export default regisvendorLayout(ProfilVendorPage);