import React from "react";
import adminLayout from "../hoc/adminLayout";
import ModalComponent from "../components/ModalComponent";

class GroupUsersPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            groupuserdata: [],
            id : 0,
            group_name:'',
            stsedit:'0',
            search: '',
            page: 1,
            rowpage: 10,
            jumData: 0,
            jumPage: 0,
            add: false,
            edit: false,
            delete: false,
            approve: false,
            ignore: false,
            export: false,
            alertSucces: false,
            alermessage: '',
            bgalert: 'bg-primary',
            btnalert: 'btn-primary',
            ordby: 'id',
            ordasc: 'asc'
        };
        this.handleSave = this.handleSave.bind(this);
        this.SearchBy = this.SearchBy.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.GroupUserList = this.GroupUserList.bind(this);
    }

    componentDidMount() {
        this.GroupUserList();
        const usAccess = JSON.parse(localStorage.getItem('UsAccess'));
        const MenuAcces = usAccess.filter(i => i.urlto == 'groupusers')
        this.setState({
            add : MenuAcces[0].madd,
            edit : MenuAcces[0].medit,
            delete : MenuAcces[0].mdelete,
            approve : MenuAcces[0].mapprove,
            ignore : MenuAcces[0].mignore,
            export : MenuAcces[0].mexport
        })
    }

    PageList(page){
        this.setState({
            page : page || 0
        })   
        fetch("https://admin.delpis.online/api/groupusers/view", {
            method: "post",
            body: JSON.stringify({ 
                page: page,
                rowpage: this.state.rowpage,
                ordby: this.state.ordby,
                ordasc: this.state.ordasc
            }),
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((response) => {
            this.setState({
                groupuserdata : response['data'] || [],
                jumData: response['jumData'] || 0,
                jumPage: response['jumPage'] || 0
            })
        })
    }
      
    OrderBy(ordby) {    
        // console.log(this.state.ordby + ' = ' + ordby)
        if(this.state.ordby !== ordby){
            this.setState({ordasc : 'asc'})
        }else{
            if(this.state.ordasc === "asc"){
                this.setState({ordasc : 'desc'})
            }else{
                this.setState({ordasc : 'asc'})            
            }         
        }
        
        this.setState({
            ordby : ordby || ''
        })     
        fetch("https://admin.delpis.online/api/groupusers/view", {
            method: "post",
            body: JSON.stringify({
                page: this.state.page,
                rowpage: this.state.rowpage,
                ordby: ordby,
                ordasc: this.state.ordasc
            }),
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((response) => {
            this.setState({
                groupuserdata : response['data'] || [],
                jumData: response['jumData'] || 0,
                jumPage: response['jumPage'] || 0
            })
        })
    }

    GroupUserList() {         
        fetch("https://admin.delpis.online/api/groupusers/view", {
            method: "post",
            body: JSON.stringify({
                page: this.state.page,
                rowpage: this.state.rowpage,
                ordby: this.state.ordby,
                ordasc: this.state.ordasc
            }),
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((response) => {
            this.setState({
                groupuserdata : response['data'] || [],
                jumData: response['jumData'] || 0,
                jumPage: response['jumPage'] || 0
            })
        })
    }

    SearchBy(event){                   
        fetch("https://admin.delpis.online/api/groupusers/search", {
            method: "post",
            body: JSON.stringify({
                search: this.state.search
            }),
            headers:{
                'Content-Type': 'application/json'
            }
        })        
        .then(response => response.json())
        .then((response) => {
            if(response['status'] === false){
                this.setState({
                    alertSucces : true,
                    alermessage: response['message'],
                    bgalert: "bg-warning",
                    btnalert: "btn-warning"
                })
                // alert(response['message']);
            }else{
                this.setState({groupuserdata : response['data'] || []})
            }
        })
        .catch(function(error) {
            console.log("Search failed", error);
        });
        // this.setState({person})
    }

    viewedit(item){
        this.setState({
            id : item.id,
            group_name : item.group_name || '',
            stsedit : '1'
        })
    }

    adddata(){
        this.setState({
            id:'',
            group_name:''
        })
    }

    delete(iduser){
        this.setState({
            id : iduser
        })  
        // this.setState({groupuserdata})
    }

    handleDelete(event){
        event.preventDefault();
        const usToken = localStorage.getItem('UsToken');        
        fetch("https://admin.delpis.online/api/groupusers/delete", {
            method: "post",
            body: JSON.stringify({
                id: this.state.id,
                token: usToken
            }),
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(() => {
            const data = this.state.groupuserdata.filter(i => i.id !== this.state.id)
            this.setState({
                groupuserdata : data,
                alertSucces : true,
                alermessage: "Deleted Succes",
                bgalert: "bg-danger",
                btnalert: "btn-danger"
            })
            // alert("Deleted Succes");
            // console.log("Deleted success");
        })
        .catch(function(error) {
            console.log("Deleted failed", error);
        });
        // this.setState({groupuserdata})
    }

    handleSave(event){
        event.preventDefault();
            const group_name = this.state.group_name;
            if(group_name == "" || group_name == null){
                this.setState({
                    alertSucces : true,
                    alermessage: "Group Name harus di isi",
                    bgalert: "bg-warning",
                    btnalert: "btn-warning"
                })
                // alert("Group Name harus di isi");
                return;
            }
            const usToken = localStorage.getItem('UsToken');   
            // const datsim = { username, email, password }
            // console.log(datsim);
            if(this.state.stsedit == '1'){
                fetch("https://admin.delpis.online/api/groupusers/update", {
                    method: "post",
                    body: JSON.stringify({
                        id: this.state.id, 
                        group_name: group_name,
                        token: usToken
                    }),
                    headers:{
                        'Content-Type': 'application/json'
                    }
                })
                .then(() => {
                    this.GroupUserList();
                    // console.log("Request succeess");
                    this.setState({
                        alertSucces : true,
                        alermessage: "Updated Succes",
                        bgalert: "bg-success",
                        btnalert: "btn-success"
                    })  
                    // alert("Updated Succes");
                    this.TombolElement.click();
                })
                .catch(function(error) {
                    console.log("Request failed", error);
                });
            }else{                
                fetch("https://admin.delpis.online/api/groupusers/register", {
                    method: "post",
                    body: JSON.stringify({
                        group_name: group_name,
                        token: usToken
                    }),
                    headers:{
                        'Content-Type': 'application/json'
                    }
                })
                .then(() => {
                    this.GroupUserList();
                    // alert("Save Succes");
                    this.setState({
                        alertSucces : true,
                        alermessage: "Save Succes",
                        bgalert: "bg-success",
                        btnalert: "btn-success"
                    })  
                    this.TombolElement.click();
                    // console.log("Request succeess");
                })
                .catch(function(error) {
                    console.log("Request failed", error);
                });
            }
    }

    // handleUpdate(event){
    //     event.preventDefault();
    // }
    
    modalFooterDeleted(){      

        return <>
            <div style={{width:"100%"}}>
                <button className="btn btn-success" onClick={this.handleDelete}
                    data-bs-dismiss="modal">Yes</button>
            &nbsp;
            <button className="btn btn-danger"
                data-bs-dismiss="modal">No</button>
            </div>
        </>;
    }

    modalDeleted(){
        return <>
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                <form>
                    <div className="mb-3">
                        <label htmlFor="group_name" className="form-label">Apakah anda yakin ingin menghapus data?</label>
                    </div>
                </form>
            </div>
        </>;
    }

    modalFooterContent(){
        return <>
            <div style={{width:"100%"}}>
                <button className="btn btn-success" onClick={this.handleSave}>Save</button> 
                &nbsp;
                <button className="btn btn-danger" ref={tombol => this.TombolElement = tombol}
                    data-bs-dismiss="modal">Cancel</button> 
            </div>
        </>;
    }

    modalContent(){
        return <>
            {/* <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Group User Data</button>
                </li>
            </ul> */}
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                    <form>
                        <div className="mb-3">
                            <label htmlFor="group_name" className="form-label">Group User <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="group_name" aria-describedby="Group_name"
                                onChange={(e) => this.setState({group_name : e.target.value})}
                                value={ this.state.group_name }/>
                        </div>
                    </form>
                </div>
            </div>
        </>;
    }

    render(){
        var no = ((this.state.page - 1) * this.state.rowpage) + 1;
        
        const paging = [];
        for(let j = 1; j <= this.state.jumPage; j++) {
            // console.log(j);
            paging.push(<li className="page-item"><a className="page-link" href="#"
                            onClick={this.PageList.bind(this, j)}>{j}</a></li>);
        }

        const groupuserdata = this.state.groupuserdata.map((item, i) => (

            <tr>
                <td>{ no++ }</td>
                <td>{ item.group_name }</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>
                    <div className="dropdown table-action-dropdown">
                        <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButtonSM" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v" aria-hidden="true"></i></button>
                        <ul className="dropdown-menu dropdown-prof" aria-labelledby="dropdownMenuButtonSM">
                            {this.state.edit ? (
                                <li><a className="dropdown-item" href="#" onClick={this.viewedit.bind(this, item)}
                                data-bs-toggle="modal" data-bs-target="#exampleModalDefault">
                               <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Edit</a></li>
                            ) : (
                                ''
                            )}                            
                            <div className="dropdown-divider"></div>      
                            {this.state.delete && item.id !== 2? (
                                <li><a className="dropdown-item text-danger" href="#" onClick={this.delete.bind(this, item.id)}
                                data-bs-toggle="modal" data-bs-target="#deletedModal">
                                <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete</a></li>
                            ) : (
                                ''
                            )}                    
                            
                        </ul>
                    </div>
                </td>
            </tr>
        ));
        
        return (
            <>
            
            <div>
                <div className="bd-example">
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">User Management</a></li>
                    <li className="breadcrumb-item"><a href="#">Grup User</a></li>
                </ol>
                </nav>
                </div>
            </div>
            <div className="table-container">
                <div className="row">
                    <div className="col-12">
                        {/* <button className="btn btn-primary" type="button">Add User</button> */}
                        {this.state.add ? (
                            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalDefault"
                                onClick={this.adddata.bind(this)}>
                                Add Group User
                            </button>
                        ) : (
                            ''
                        )}
                        <ModalComponent title="Deleted Confirmation" footerContent={this.modalFooterDeleted()} content={this.modalDeleted()} dataBsBackdrop="static" id="deletedModal"/>
                        <ModalComponent title="Add Group Users" footerContent={this.modalFooterContent()} content={this.modalContent()} dataBsBackdrop="static" id="exampleModalDefault"/>
                    </div>
                </div>
                <div className="row">
                    &nbsp;
                </div>
                <div className="row">
                    {/* <div className="col-1">
                        <label htmlFor="exampleInputcontactnumber" className="form-label">Search</label>
                    </div> */}
                    <div className="col-5">
                        <input type="text" className="form-control" id="search" aria-describedby="search" 
                            onChange={(e) => this.setState({search : e.target.value})}
                            value={ this.state.search }/>
                    </div>
                    <div className="col-3">
                        <button type="button" className="btn btn-primary" onClick={this.SearchBy.bind(this)}>
                            Search
                        </button>
                    </div>
                </div>
                                    
                <div className="row">
                    <div className="col-12">
                        &nbsp;
                    </div>
                </div>             

                {this.state.alertSucces && 
                <div className="ModalAlert">
                    <div className="alertNew">
                        <div className="modal-content">                   
                            <div className={`modal-header ${this.state.bgalert}`}>
                                <h5 className="modal-title" id="exampleModalLabel">Alert Message</h5>
                                <button type="button" className="btn-close" aria-label="Close"
                                    onClick={(e) => this.setState({alertSucces : false})}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div className="modal-body text-black">
                                <center><h1>{ this.state.alermessage }</h1></center>
                            </div>            
                            <div style={{width:"100%"}}>
                                <center>
                                <button className={`btn ${this.state.btnalert}`} onClick={(e) => this.setState({alertSucces : false})}>
                                    OK</button> 
                                </center>
                            </div>
                            <br></br>
                        </div>
                    </div>
                </div>
                }

                <div className="d-flex text-muted">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th className="Col-klik" onClick={(e) => this.OrderBy('group_name')}>Grup User  &nbsp;&nbsp;<img src={require('./../assets/images/arrow.png')} alt="" height='25' width='25'></img></th>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { groupuserdata }
                        </tbody>
                    </table>
                </div>
                <nav className="table-bottom-center-pagination" aria-label="Page navigation example ">
                    <ul className="pagination">
                        {this.state.page === 1 ? (
                            ''
                        ):(
                            <li className="page-item">
                            <a className="page-link" href="#" aria-label="Previous"
                                onClick={this.PageList.bind(this, this.state.page - 1)}>
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Previous</span>
                            </a>
                            </li>
                        )}
                        { paging }
                        {/* <li className="page-item"><a className="page-link" href="#">1</a></li>
                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                        <li className="page-item"><a className="page-link" href="#">3</a></li> */}
                        {this.state.page === this.state.jumPage ? (
                            ''
                        ):(
                            <li className="page-item">
                            <a className="page-link" href="#" aria-label="Next"
                                onClick={this.PageList.bind(this, this.state.page + 1)}>
                                <span aria-hidden="true">&raquo;</span>
                                <span className="sr-only">Next</span>
                            </a>
                            </li>
                        )}
                    </ul>
                </nav>
            </div>
            </>
          );
    }
}

export default adminLayout(GroupUsersPage);