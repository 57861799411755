import React from "react";
import "../../assets/css/login.css";
import { Link } from 'react-router-dom';
import authLayout from "../../hoc/authLayout";
import AgreementComponent from "../../components/AgreementComponent";
 
class LoginPage extends React.Component {
    constructor(props){
        super(props);
 
        this.state = {
            username:'',
            password:'',
            isLogin:'',
            alertSucces: false,
            alermessage: '',
            bgalert: 'bg-primary',
            btnalert: 'btn-primary',
            typepass:'password'
        };
        this.showpass=this.showpass.bind(this);
    }
 
    modalFooterContent(){
        return <>
            <div style={{width:"100%"}}>
                <button className="btn btn-success" onClick={this.GotoVendor}>Saya Setuju</button>
            </div>
        </>;
    }
 
    GotoVendor(event){
        event.preventDefault();
        window.location="/vendor";        
    }
 
    handleLogin(event){
        event.preventDefault();
        const username = this.state.username;
        const password = this.state.password;
       
        this.setState({
            bgalert: "bg-warning",
            btnalert: "btn-warning"
        })  
       
        var url = "https://vendor.delpis.online/api/auth/login";
        var home = "/home";
        const pathname = window.location.pathname;
        console.log(pathname);
        if(pathname == '/Admin' || pathname == '/admin'){
            url = "https://admin.delpis.online/api/auth/login";
            home = "/home";
        }
       
        // console.log(url);
        fetch(url, {
            method: "post",
            //mode: 'no-cors',
            body: JSON.stringify({
                user_name: username,
                password: password,
            }),
            headers:{
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
        .then((response) => {
            // this.UserList();
            // console.log(response['token']);
            if(response['status'] === false){
                // alert(response['message']);
 
                this.setState({
                    bgalert: "bg-danger",
                    btnalert: "btn-danger"
                })    
                this.setState({
                    alertSucces : true,
                    alermessage: response['message']
                })  
 
            }else{
                if(username === password){
                    localStorage.setItem('UsToken', response['token']);
                    window.location = "/changepassword";
                }else{
                    localStorage.setItem('UsToken', response['token']);
                    window.location = home;
                    // this.props.history.push("/home");
                    console.log("Request succeeded with  response");
                }
            }
        })
        .catch(function(error) {
            // this.setState({isLogin : ""});
            console.log("Request failed", error);
        });
    }
 
    showpass(){
        if(this.state.typepass == "password"){
            this.setState({typepass : 'text'})
        }else{
            this.setState({typepass : 'password'})
        }
    }
    render(){
 
        return <>
            <form className="login-form">
                <div className="d-flex align-items-center my-4">
                    <h1 className="text-center fw-normal mb-0 me-3">Sign In</h1>
                </div>
                {/* <!-- Email input --> */}
                <div className="form-outline mb-4">
                    {/* <label className="form-label" htmlFor="form3Example3">Email address</label> */}
                    <input type="email" id="form3Example3" className="form-control form-control-lg"
                    placeholder="Enter Username" onChange={(e) => this.setState({username : e.target.value})}/>
                </div>
 
                {/* <!-- Password input --> */}
                <div className="form-outline mb-3">
                    {/* <label className="form-label" htmlFor="form3Example4">Password</label> */}
                    <div className="row">
                        <div className="input-group mb-3">
                            <input type={this.state.typepass} id="form3Example4" className="form-control form-control-lg"
                            placeholder="Enter password" onChange={(e) => this.setState({password : e.target.value})}/>
                            <span className="input-group-text" id="basic-addon2"><i className={`fa fa-eye ` + (this.state.typepass === 'password' ? `fa-eye-slash` : `fa-eye`)}
                                        onClick={this.showpass}></i></span>
                        </div>
                        {/* <div className="col-md-1 bg-mata">
                            <i class={`mata fa ` + (this.state.typepass === 'password' ? `fa-eye-slash` : `fa-eye`)}
                                onClick={this.showpass}></i>
                        </div>                         */}
                    </div>
                </div>
 
                <div className="form-outline mb-3">
                    {/* <!-- Checkbox --> */}
                    {/* <div className="form-check mb-0">
                    <input className="form-check-input me-2" type="checkbox" value="" id="form2Example3" />
                    <label className="form-check-label" htmlFor="form2Example3">
                        Remember me
                    </label>
                    </div> */}
                    <Link to="/forgot-password" className="text-body">Forgot password?</Link>
                </div>
 
                <div className="text-center text-lg-start mt-4 pt-2">
                    <Link to="#" type="button" className="btn btn-primary btn-lg"
                     onClick={this.handleLogin.bind(this)} >Login</Link>
                     {
                        window.location.pathname === '/Admin' || window.location.pathname === '/admin' ?
                            ''
                        :
                            <p className="small fw-bold mt-2 pt-1 mb-0">Now To E-Procurement? <a href="#!"
                            className="link-danger"  data-bs-toggle="modal" data-bs-target="#rightModalDefault">Pendaftaran Rekanan</a></p>
                     }                    
                </div>
               
                <AgreementComponent title="Syarat & Ketentuan" footerContent={this.modalFooterContent()} className="right" dataBsBackdrop="static" id="rightModalDefault"/>
            </form>
 
            {this.state.alertSucces &&
                <div className="ModalAlert">
                    <div className="alertNew">
                        <div className="modal-content">                  
                            <div className={`modal-header ${this.state.bgalert}`}>
                                <h5 className="modal-title" id="exampleModalLabel">Alert Message</h5>
                                <button type="button" className="btn-close" aria-label="Close"
                                    onClick={(e) => this.setState({alertSucces : false})}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div className="modal-body text-black">
                                <center><h1>{ this.state.alermessage }</h1></center>
                            </div>            
                            <div style={{width:"100%"}}>
                                <center>
                                <button className={`btn ${this.state.btnalert}`} onClick={(e) => this.setState({alertSucces : false})}>
                                    OK</button>
                                </center>
                            </div>
                            <br></br>
                        </div>
                    </div>
                </div>
                }
        </>
    }
}
 
export default authLayout(LoginPage);
