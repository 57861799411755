import React from "react";
import vendorLayout from "../../hoc/vendorLayout";
import ModalComponent from "../../components/ModalComponent";

class VendorPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            compName:'',
            compNPWP:'',
            compAddr:'',
            compCity:'',
            compPostcode:'',
            compProv:'',
            compFax:'',
            compPhone:'',
            compStatus:'',
            compUser:'',
            compPass:'',
            contPhone:'',
            contName:'',
            contPosisi:'',
            contNPWP:'',
            contEmail:'',
            contact:[],
            id:'',
            editsts:0,
            alertSucces: false,
            alermessage: '',
            notifSuccess: false,
            bgalert: 'bg-primary',
            btnalert: 'btn-primary',
            typepass:'password'
        };
        this.handleSaveContact = this.handleSaveContact.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.onNumber = this.onNumber.bind(this);
        this.onNPWP = this.onNPWP.bind(this);
        this.onPhone = this.onPhone.bind(this);
        this.onCompNPWP = this.onCompNPWP.bind(this);
        this.onFax = this.onFax.bind(this);
        this.onPostcode = this.onPostcode.bind(this);
        this.showpass=this.showpass.bind(this);
    }

    // componentDidMount() {
    //     const VendorContact = JSON.parse(localStorage.getItem('ContactVendor'));
    //     // const VendorContact = localStorage.getItem('ContactVendor');
    //     if(VendorContact){
    //         this.state.contact = VendorContact;
    //         // console.log(this.state.contact);
    //     }
    // }

    adddata(){
        this.setState({
            contPhone:'',
            contName:'',
            contPosisi:'',
            contNPWP:'',
            contEmail:'',
        })
    }   

    viewedit(item){
        this.setState({
            contPhone: item.contPhone || '',
            contName: item.contname || '',
            contPosisi: item.contPosisi || '',
            contNPWP: item.contNPWP || '',
            contEmail: item.contEmail || '',
            id: item.contPhone || '',
            editsts:1
        })
    }

    delete(nameContact){
        this.setState({
            id : nameContact
        })  
        // this.setState({groupuserdata})
    }

    handleDelete(event){
        const data = this.state.contact.filter(i => i.contPhone !== this.state.id)
        this.state.contact = data;
        localStorage.setItem("ContactVendor", JSON.stringify(this.state.contact));
        this.setState({
            person : data,
            alertSucces : true,
            alermessage: "Deleted Succes",
            bgalert: "bg-danger",
            btnalert: "btn-danger"})
    }
    
    onNumber(e){
       const re = /^[0-9\b]+$/;
       if (e.target.value === '' || re.test(e.target.value)) {
          this.setState({contPhone: e.target.value})
       }
    }

    onPhone(e){
       const re = /^[0-9\b]+$/;
       if (e.target.value === '' || re.test(e.target.value)) {
          this.setState({compPhone: e.target.value})
       }
    }

    onFax(e){
       const re = /^[0-9\b]+$/;
       if (e.target.value === '' || re.test(e.target.value)) {
          this.setState({compFax: e.target.value})
       }
    }
    
    onPostcode(e){
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
           this.setState({compPostcode: e.target.value})
        }
     }
   
    onNPWP(e){
       const re = /^[0-9\b]+$/;
       if (e.target.value === '' || re.test(e.target.value)) {
          this.setState({contNPWP: e.target.value})
       }
    }
   
    onCompNPWP(e){
       const re = /^[0-9\b]+$/;
       if (e.target.value === '' || re.test(e.target.value)) {
          this.setState({compNPWP: e.target.value})
       }
    }
    
    modalFooterDeleted(){      

        return <>
            <div style={{width:"100%"}}>
                <button className="btn btn-success" onClick={this.handleDelete}
                    data-bs-dismiss="modal">Yes</button>
            &nbsp;
            <button className="btn btn-danger"
                data-bs-dismiss="modal">No</button>
            </div>
        </>;
    }

    modalDeleted(){
        return <>
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                <form>
                    <div className="mb-3">
                        <label htmlFor="group_name" className="form-label">Apakah anda yakin ingin menghapus data?</label>
                    </div>
                </form>
            </div>
        </>;
    }

    modalFooterContent(){
        return <>
            <div style={{width:"100%"}}>
                <button className="btn btn-success" onClick={this.handleSaveContact}>Save</button> 
                &nbsp;
                <button className="btn btn-danger" data-bs-dismiss="modal"
                    ref={tombol => this.TombolElement = tombol}>Cancel</button> 
            </div>
        </>;
    }

    modalContent(){
        return <>
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                    <form>
                        <div className="mb-3">
                            <label htmlFor="ContactName" className="form-label">Nama Contact <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="ContactName" aria-describedby="Group_name"
                                onChange={(e) => this.setState({contName : e.target.value})}
                                value={ this.state.contName }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="ContactPhone" className="form-label">No. Handphone <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="ContactPhone" aria-describedby="Group_name"
                                onChange={this.onNumber}
                                value={ this.state.contPhone }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="ContactEmail" className="form-label">Email Contact <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="ContactEmail" aria-describedby="Group_name"
                                onChange={(e) => this.setState({contEmail : e.target.value})}
                                value={ this.state.contEmail }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="ContactPosisi" className="form-label">Posisi Contact <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="ContactPosisi" aria-describedby="Group_name"
                                onChange={(e) => this.setState({contPosisi : e.target.value})}
                                value={ this.state.contPosisi }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="ContactNPWP" className="form-label">NPWP Contact <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="ContactNPWP" aria-describedby="Group_name"
                                onChange={this.onNPWP}
                                value={ this.state.contNPWP }
                                maxLength={16}/>
                        </div>
                    </form>
                </div>
            </div>
        </>;
    }

    handleSaveContact(event){
        event.preventDefault();
        this.setState({
            bgalert: "bg-warning",
            btnalert: "btn-warning"
        })

        if(this.state.contName == "" || this.state.contName == null){
            this.setState({
                alertSucces : true,
                alermessage: "Nama Contact harus di isi"
            })  
            return;
        }

        if(this.state.contPhone == "" || this.state.contPhone == null){
            this.setState({
                alertSucces : true,
                alermessage: "No. Handphone Contact harus di isi"
            })  
            return;
        }

        if(this.state.contEmail == "" || this.state.contEmail == null){
            this.setState({
                alertSucces : true,
                alermessage: "Nama Contact harus di isi"
            })  
            return;
        }

        if(this.state.contPosisi == "" || this.state.contPosisi == null){
            this.setState({
                alertSucces : true,
                alermessage: "Nama Contact harus di isi"
            })  
            return;
        }

        if(this.state.contNPWP == "" || this.state.contNPWP == null){
            this.setState({
                alertSucces : true,
                alermessage: "Nama Contact harus di isi"
            })  
            return;
        }
        
        // const datacontact = JSON.stringify({
        //     contname: this.state.contName, 
        //     contPhone: this.state.contPhone,
        //     contEmail: this.state.contEmail,
        //     contPosisi: this.state.contPosisi,
        //     contNPWP: this.state.contNPWP, 
        // })
        if(this.state.editsts === 1){
            const newData = Object.values(this.state.contact);

            const index = newData.findIndex(item => item.contPhone === this.state.id);
            console.log('index : ' + index);
            // Lakukan perubahan pada salinan array
            if (index !== -1) {
                newData[index] = { ...newData[index],
                    contname: this.state.contName,
                    contPhone: this.state.contPhone,
                    contEmail: this.state.contEmail,
                    contPosisi: this.state.contPosisi,
                    contNPWP: this.state.contNPWP
                };
                // console.log(newData);
                this.state.contact = newData; // Update state dengan array yang sudah diubah
                localStorage.setItem("ContactVendor", JSON.stringify(this.state.contact));
            }
        } else {

            const oldArray = Object.values(this.state.contact);
            var newStateArray = oldArray.slice();
            newStateArray.push({
                "contname": this.state.contName,
                "contPhone": this.state.contPhone,
                "contEmail": this.state.contEmail,
                "contPosisi": this.state.contPosisi,
                "contNPWP": this.state.contNPWP
                });
            this.state.contact = newStateArray;
            localStorage.setItem("ContactVendor", JSON.stringify(this.state.contact));
        }

        this.TombolElement.click();
    }

    handleRegis(event){
        event.preventDefault();
        
        this.setState({
            bgalert: "bg-warning",
            btnalert: "btn-warning"
        })  

        if(this.state.compName == "" || this.state.compName == null){
            this.setState({
                alertSucces : true,
                alermessage: "Nama Perusahaan harus di isi"
            })  
            return;
        }

        if(this.state.compNPWP == "" || this.state.compNPWP == null){
            this.setState({
                alertSucces : true,
                alermessage: "NPWP Perusahaan harus di isi"
            })  
            return;
        }

        if(this.state.compStatus == "" || this.state.compStatus == null){
            this.setState({
                alertSucces : true,
                alermessage: "Status harus di isi"
            })  
            return;
        }

        if(this.state.compAddr == "" || this.state.compAddr == null){
            this.setState({
                alertSucces : true,
                alermessage: "Alamat Perusahaan harus di isi"
            })  
            return;
        }

        if(this.state.compCity == "" || this.state.compCity == null){
            this.setState({
                alertSucces : true,
                alermessage: "Kota harus di isi"
            })  
            return;
        }

        if(this.state.compProv == "" || this.state.compProv == null){
            this.setState({
                alertSucces : true,
                alermessage: "Provinsi harus di isi"
            })  
            return;
        }

        if(this.state.compPostcode == "" || this.state.compPostcode == null){
            this.setState({
                alertSucces : true,
                alermessage: "Kode Pos harus di isi"
            })  
            return;
        }

        if(this.state.compPhone == "" || this.state.compPhone == null){
            this.setState({
                alertSucces : true,
                alermessage: "Nomor Telepon Perusahaan harus di isi"
            })  
            return;
        }

        if(this.state.compFax == "" || this.state.compFax == null){
            this.setState({
                alertSucces : true,
                alermessage: "Nomor Fax Perusahaan harus di isi"
            })  
            return;
        }

        if(this.state.compEmail == "" || this.state.compEmail == null){
            this.setState({
                alertSucces : true,
                alermessage: "Email Perusahaan harus di isi"
            })
            return;
        }

        // var datacontact = this.state

        fetch("https://vendor.delpis.online/api/vendor/register", {
            method: "post",
            body: JSON.stringify({
                COMPANY_NAME: this.state.compName,
                NPWP: this.state.compNPWP,
                STATUS: this.state.compStatus,
                ADDRESS: this.state.compAddr,
                CITY: this.state.compCity,
                PROVINCE: this.state.compProv,
                POSTAL_CODE: this.state.compPostcode,
                PHONE: this.state.compPhone,
                FAX: this.state.compFax,
                EMAIL: this.state.compEmail,
                USER_NAME: this.state.compUser,
                PASSWORD: this.state.compPass,
                DATACONTACT: this.state.contact
            }),
            headers:{
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
        .then((response) => {
            // this.UserList();
            // console.log(response['token']);
            if(response['status'] === false){
                // alert(response['message']);

                this.setState({
                    bgalert: "bg-danger",
                    btnalert: "btn-danger"
                })    
                this.setState({
                    alertSucces : true,
                    alermessage: response['message']
                })  

            }else{
                this.setState({
                    bgalert: "bg-success",
                    btnalert: "btn-success"
                })    
                this.setState({
                    notifSuccess: true
                }) 
                // window.location = "/";
            }
        })
        .catch(function(error) {
            // this.setState({isLogin : ""});
            console.log("Request failed", error);
        });
    }

    showpass(){
        if(this.state.typepass == "password"){
            this.setState({typepass : 'text'})
        }else{
            this.setState({typepass : 'password'})
        }
    }
    render(){
        var no = 1;

        const VendorContact = JSON.parse(localStorage.getItem('ContactVendor'));
        // const VendorContact = localStorage.getItem('ContactVendor');
        if(VendorContact){
            this.state.contact = VendorContact;
            // console.log(this.state.contact);
        }
        // console.log(this.state.contact);
        const IsiContact = this.state.contact.map((item, i) => (

            <tr>
                <td>{ no++ }</td>
                <td>{ item.contname }</td>
                <td>{ item.contPhone }</td>
                <td>{ item.contEmail }</td>
                <td>{ item.contPosisi }</td>
                <td>{ item.contNPWP }</td>
                <td>
                    <div className="dropdown table-action-dropdown">
                        <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButtonSM" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v" aria-hidden="true"></i></button>
                        <ul className="dropdown-menu dropdown-prof" aria-labelledby="dropdownMenuButtonSM">
                            <li><a className="dropdown-item" href="#" onClick={this.viewedit.bind(this, item)}
                                data-bs-toggle="modal" data-bs-target="#exampleModalDefault">
                                <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Edit</a></li>
                            <div className="dropdown-divider"></div>        
                            <li><a className="dropdown-item text-danger" href="#" onClick={this.delete.bind(this, item.contPhone)}
                                data-bs-toggle="modal" data-bs-target="#deletedModal">
                                <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete</a></li>                            
                        </ul>
                    </div>
                </td>
            </tr>
        ));
        // console.log(IsiContact);
        return <>
            <form>
                <div className="row">
                    <center><h3>Registrasi Penyedia</h3></center>
                </div>
                <div className="row">
                    &nbsp;
                </div>
                <div className="row bg-primary text-white">
                    <center><h1>Informasi Umum</h1></center>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="CompanyName" className="form-label">Nama Perusahaan <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="CompanyName" aria-describedby="Username"
                                onChange={(e) => this.setState({compName : e.target.value})}
                                value={ this.state.username }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="CompanyNPWP" className="form-label">NPWP Perusahaan <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="CompanyNPWP" aria-describedby="Username"
                                onChange={this.onCompNPWP}
                                value={ this.state.compNPWP }
                                maxLength={16}/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="CompanyStatus" className="form-label">Status <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <select className="form-select is-invalid" id="CompanyStatus" required=""
                                onChange={(e) => this.setState({compStatus : e.target.value})}>
                                <option disabled="" value="0">Choose...</option>
                                <option disabled="" value="Pusat">Pusat</option>
                                <option disabled="" value="Cabang">Cabang</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="CompanyAddr" className="form-label">Alamat Perusahaan <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="CompanyAddr" aria-describedby="Username"
                                onChange={(e) => this.setState({compAddr : e.target.value})}
                                value={ this.state.compAddr }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="CompanyCity" className="form-label">Kota <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="CompanyCity" aria-describedby="Username"
                                onChange={(e) => this.setState({compCity : e.target.value})}
                                value={ this.state.compCity }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="CompanyProv" className="form-label">Provinsi <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="CompanyProv" aria-describedby="Username"
                                onChange={(e) => this.setState({compProv : e.target.value})}
                                value={ this.state.compProv }/>
                        </div>

                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="CompanyPostCode" className="form-label">Kode Pos <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="CompanyPostCode" aria-describedby="Username"
                                onChange={this.onPostcode}
                                value={ this.state.compPostcode }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="CompanyPhone" className="form-label">Nomor Telpon Perusahaan <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="CompanyPhone" aria-describedby="Username"
                                onChange={this.onPhone}
                                value={ this.state.compPhone }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="CompanyFax" className="form-label">Nomor Fax Perusahaan <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="CompanyFax" aria-describedby="Username"
                                onChange={this.onFax}
                                value={ this.state.compFax }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="CompanyEmail" className="form-label">Email Perusahaan <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="CompanyEmail" aria-describedby="Username"
                                onChange={(e) => this.setState({compEmail : e.target.value})}
                                value={ this.state.compEmail }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="CompanyUser" className="form-label">Username <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="CompanyUser" aria-describedby="Username"
                                onChange={(e) => this.setState({compUser : e.target.value})}
                                value={ this.state.compUser }/>
                        </div>
                        <div className="input-group mb-3">
                            <label htmlFor="CompanyPass" className="form-label">Password <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <div className="input-group">
                                <input type={this.state.typepass} id="CompanyPass" className="form-control form-control-lg"
                                    placeholder="" onChange={(e) => this.setState({compPass : e.target.value})}/>
                                    <span className="input-group-text" id="basic-addon2"><i className={`fa fa-eye ` + (this.state.typepass === 'password' ? `fa-eye-slash` : `fa-eye`)}
                                                onClick={this.showpass}></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row bg-primary text-white">
                    <center><h1>Contact Person</h1></center>
                </div>
                <div className="row">
                    &nbsp;
                </div>
                <div className="row">
                    <div className="col-6">
                        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalDefault"
                            onClick={this.adddata.bind(this)}>
                            Add Contact
                        </button>
                    </div>
                </div>
                <div className="row">
                    &nbsp;
                </div>

                <div className="row">                    
                    <table className="table">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th className="Col-klik">Nama</th>
                                <th className="Col-klik">No. Handphone</th>
                                <th className="Col-klik">Email</th>
                                <th className="Col-klik">Posisi</th>
                                <th className="Col-klik">Npwp</th>
                                {/* <th>Status</th> */}
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { IsiContact }
                        </tbody>
                    </table>
                </div>

                <div className="text-center text-lg-start mt-4 pt-2">
                    <button className="btn btn-primary btn-lg"
                     onClick={this.handleRegis.bind(this)} >Register</button>
                </div>
            </form>

                
            <ModalComponent title="Deleted Confirmation" footerContent={this.modalFooterDeleted()} content={this.modalDeleted()} dataBsBackdrop="static" id="deletedModal"/>
            <ModalComponent title="Add Contact" footerContent={this.modalFooterContent()} content={this.modalContent()} dataBsBackdrop="static" id="exampleModalDefault"/>
                
            {this.state.alertSucces && 
                <div className="ModalAlert">
                    <div className="alertNew">
                        <div className="modal-content">                   
                            <div className={`modal-header ${this.state.bgalert}`}>
                                <h5 className="modal-title" id="exampleModalLabel">Alert Message</h5>
                                <button type="button" className="btn-close" aria-label="Close"
                                    onClick={(e) => this.setState({alertSucces : false})}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div className="modal-body text-black">
                                <center><h1>{ this.state.alermessage }</h1></center>
                            </div>            
                            <div style={{width:"100%"}}>
                                <center>
                                <button className={`btn ${this.state.btnalert}`} onClick={(e) => this.setState({alertSucces : false})}>
                                    OK</button> 
                                </center>
                            </div>
                            <br></br>
                        </div>
                    </div>
                </div>
                }
            {this.state.notifSuccess && 
                <div className="ModalAlert">
                    <div className="alertNew">
                        <div className="modal-content">                   
                            <div className={`modal-header ${this.state.bgalert}`}>
                                <h5 className="modal-title" id="exampleModalLabel">PENYEDIA</h5>
                                <button type="button" className="btn-close" aria-label="Close"
                                    onClick={(e) => this.setState({alertSucces : false})}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div className="modal-body text-black">
                                <center>
                                    <h1>Selamat {this.state.compEmail}, pendaftaran anda berhasil</h1><br></br>
                                    <h2>Silahkan cek email anda untuk konfirmasi pendaftaran</h2>
                                </center>
                            </div>            
                            <div style={{width:"100%"}}>
                                <center>
                                <button className={`btn ${this.state.btnalert}`} onClick={(e) => window.location="/"}>
                                    OK</button> 
                                </center>
                            </div>
                            <br></br>
                        </div>
                    </div>
                </div>
                }
        </>
    }
}

export default vendorLayout(VendorPage);