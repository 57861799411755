import React from "react";
import { Link } from 'react-router-dom';
import regisvendorLayout from "../RegisVendorLayout";
import ModalComponent from "../../../components/ModalComponent";

class IzinVendorPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            attachName:'',
            attachKat:'',
            attachDok:'',
            attachExp:'',
            attach:[],
            ahli:[],
            bank:[],
            izin:[],
            deed:[],
            experience:[],
            manage:[],
            SPT:[],
            contact:[],
            id:'',
            alertSucces: false,
            alermessage: '',
            bgalert: 'bg-primary',
            btnalert: 'btn-primary',
            typepass:'password'
        };
        this.handleSave = this.handleSave.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.onFile = this.onFile.bind(this);

    }
    
    componentDidMount() {
        this.AttachVendor();
    }
      
    AttachVendor() {           
        const usToken = localStorage.getItem('UsToken'); 
        fetch("https://vendor.delpis.online/api/vendor/attachment", {
            method: "post",
            body: JSON.stringify({
                token: usToken
            }),
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((response) => {
            this.setState({
                attach: response['attachmentData'] || []
            });
            
            localStorage.setItem("AttachVendor", JSON.stringify(this.state.attach));
        })
    }

    adddata(){
        this.setState({
            attachName:'',
            attachKat:'',
            attachDok:'',
            attachExp:'',
        })
    }
    
    viewedit(item){
        this.setState({
            attachName: item.attachment_name || '',
            attachKat: item.attachment_type || '',
            attachDok: item.path_file || '',
            attachExp: item.exp_date || '',
            id: item.attachment_name || '',
            editsts:1
        })
    }

    delete(Nameattach){
        this.setState({
            id : Nameattach
        })  
        // this.setState({groupuserdata})
    }

    handleDelete(event){
        const data = this.state.attach.filter(i => i.attachment_name !== this.state.id)
        this.state.attach = data;
        localStorage.setItem("AttachVendor", JSON.stringify(this.state.attach));
        this.setState({
            person : data,
            alertSucces : true,
            alermessage: "Deleted Succes",
            bgalert: "bg-danger",
            btnalert: "btn-danger"})
    }

    handleSave(event){
        event.preventDefault();
        this.setState({
            bgalert: "bg-warning",
            btnalert: "btn-warning"
        })

        if(this.state.attachName == "" || this.state.attachName == null){
            this.setState({
                alertSucces : true,
                alermessage: "Nama Dokumen harus di isi"
            })  
            return;
        }

        if(this.state.attachKat == "" || this.state.attachKat == null){
            this.setState({
                alertSucces : true,
                alermessage: "Kategori Dokumen harus di isi"
            })  
            return;
        }

        if(this.state.attachDok == "" || this.state.attachDok == null){
            this.setState({
                alertSucces : true,
                alermessage: "Dokumen harus di isi"
            })  
            return;
        }

        if(this.state.attachExp == "" || this.state.attachExp == null){
            this.setState({
                alertSucces : true,
                alermessage: "Expired Date harus di isi"
            })  
            return;
        }
        
        if(this.state.editsts === 1){
            const newData = Object.values(this.state.attach);

            const index = newData.findIndex(item => item.attachment_name === this.state.id);
            console.log('index : ' + index);
            // Lakukan perubahan pada salinan array
            if (index !== -1) {
                newData[index] = { ...newData[index],
                    attachment_name: this.state.attachName,
                    attachment_type: this.state.attachKat,
                    exp_date: this.state.attachExp,
                    path_file: this.state.attachDok
                };
                // console.log(newData);
                this.state.attach = newData; // Update state dengan array yang sudah diubah
                localStorage.setItem("AttachVendor", JSON.stringify(this.state.attach));
            }
        } else {

            const oldArray = Object.values(this.state.attach);
            var newStateArray = oldArray.slice();
            newStateArray.push({
                "attachment_name": this.state.attachName,
                "attachment_type": this.state.attachKat,
                "exp_date": this.state.attachExp,
                "path_file": this.state.attachDok
                });
            this.state.attach = newStateArray;

            localStorage.setItem("AttachVendor", JSON.stringify(this.state.attach));
        }
        this.TombolElement.click();
    }
   
    onFile(e){
        let reader = new FileReader();
        reader.onload = () => {
            this.setState({attachDok: reader.result})
        }        
        reader.readAsDataURL(e.target.files[0]);
        console.log(this.state.attachDok)
    }

    modalFooterDeleted(){      

        return <>
            <div style={{width:"100%"}}>
                <button className="btn btn-success" onClick={this.handleDelete}
                    data-bs-dismiss="modal">Yes</button>
            &nbsp;
            <button className="btn btn-danger"
                data-bs-dismiss="modal">No</button>
            </div>
        </>;
    }

    modalDeleted(){
        return <>
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                <form>
                    <div className="mb-3">
                        <label htmlFor="group_name" className="form-label">Apakah anda yakin ingin menghapus data?</label>
                    </div>
                </form>
            </div>
        </>;
    }

    modalFooterContent(){
        return <>
            <div style={{width:"100%"}}>
                <button className="btn btn-success" onClick={this.handleSave}>Save</button> 
                &nbsp;
                <button className="btn btn-danger" ref={tombol => this.TombolElement = tombol}
                    data-bs-dismiss="modal">Cancel</button> 
            </div>
        </>;
    }

    modalContent(){
        return <>
            {/* <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Group User Data</button>
                </li>
            </ul> */}
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                    <form>
                        <div className="mb-3">
                            <label htmlFor="attachName" className="form-label">Nama Dokumen <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="attachName" aria-describedby="Group_name"
                                onChange={(e) => this.setState({attachName : e.target.value})}
                                value={ this.state.attachName }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="attachKat" className="form-label">Kategori Dokumen <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="attachKat" aria-describedby="Group_name"
                                onChange={(e) => this.setState({attachKat : e.target.value})}
                                value={ this.state.attachKat }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="attachDok" className="form-label">Dokumen <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="file" className="form-control" id="attachDok" aria-describedby="Group_name"
                                onChange={this.onFile}/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="attachExp" className="form-label">Exp Date <span class="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="date" className="form-control" id="attachExp" aria-describedby="Group_name"
                                onChange={(e) => this.setState({attachExp : e.target.value})}
                                value={ this.state.attachExp }/>
                        </div>
                    </form>
                </div>
            </div>
        </>;
    }

    handleRegisNext(event){
        event.preventDefault();
        const usToken = localStorage.getItem('UsToken'); 
           
            this.setState({
                bgalert: "bg-warning",
                btnalert: "btn-warning"
            })  

        fetch("https://vendor.delpis.online/api/vendor/fullregister", {
            method: "post",
            body: JSON.stringify({
                token: usToken,
                DataAttach: this.state.attach,
                DataAhli: this.state.ahli,
                DataBank: this.state.bank,
                DataIzin: this.state.izin,
                DataDeed: this.state.deed,
                DataExperience: this.state.experience,
                DataManage: this.state.manage,
                DataSPT: this.state.SPT
            }),
            headers:{
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
        .then((response) => {
            if(response['status'] === false){
                // alert(response['message']);

                this.setState({
                    bgalert: "bg-danger",
                    btnalert: "btn-danger"
                })    
                this.setState({
                    alertSucces : true,
                    alermessage: response['message']
                })  

            }else{
                this.setState({
                    bgalert: "bg-success",
                    btnalert: "btn-success"
                })    
                this.setState({
                    notifSuccess: true
                }) 
                // window.location = "/";
            }
        })
        .catch(function(error) {
            // this.setState({isLogin : ""});
            console.log("Request failed", error);
        });
    }

    render(){
        var no = 1;

        const VendorExpert = JSON.parse(localStorage.getItem('ExpertVendor'));
        if(VendorExpert){
            this.state.ahli = VendorExpert;
        }

        const VendorBank = JSON.parse(localStorage.getItem('BankVendor'));
        if(VendorBank){
            this.state.bank = VendorBank;
        }
        
        const VendorIzin = JSON.parse(localStorage.getItem('IzinVendor'));
        if(VendorIzin){
            this.state.izin = VendorIzin;
        }

        const VendorDeed = JSON.parse(localStorage.getItem('DeedVendor'));
        if(VendorDeed){
            this.state.deed = VendorDeed;
        }

        const VendorExperience = JSON.parse(localStorage.getItem('ExperienceVendor'));
        if(VendorExperience){
            this.state.experience = VendorExperience;
        }

        const VendorManage = JSON.parse(localStorage.getItem('ManageVendor'));
        if(VendorManage){
            this.state.manage = VendorManage;
        }

        const VendorSPT = JSON.parse(localStorage.getItem('SPTVendor'));
        if(VendorSPT){
            this.state.SPT = VendorSPT;
        }

        // const VendorContact = JSON.parse(localStorage.getItem('ContactVendor'));
        // if(VendorContact){
        //     this.state.contact = VendorContact;
        // }

        const VendorAttach = JSON.parse(localStorage.getItem('AttachVendor'));
        if(VendorAttach){
            this.state.attach = VendorAttach;
        }
        // console.log(this.state.contact);
        const AttachVendor = this.state.attach.map((item, i) => (

            <tr>
                <td>{ no++ }</td>
                <td>{ item.attachment_name }</td>
                <td>{ item.attachment_type }</td>  
                <td>
                <img
                    src={ item.path_file } // Adjust the MIME type according to your image type
                    alt="File"
                    width="200px"
                />    
                </td>   
                <td>{ item.exp_date }</td>      
                <td>
                    <div className="dropdown table-action-dropdown">
                        <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButtonSM" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v" aria-hidden="true"></i></button>
                        <ul className="dropdown-menu dropdown-prof" aria-labelledby="dropdownMenuButtonSM">
                            <li><a className="dropdown-item" href="#" onClick={this.viewedit.bind(this, item)}
                                data-bs-toggle="modal" data-bs-target="#exampleModalDefault">
                                <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Edit</a></li>
                            <div className="dropdown-divider"></div>        
                            <li><a className="dropdown-item text-danger" href="#" onClick={this.delete.bind(this, item.attachment_name)}
                                data-bs-toggle="modal" data-bs-target="#deletedModal">
                                <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete</a></li>                            
                        </ul>
                    </div>
                </td>
            </tr>
        ));

        return <>
            <div>
                <div className="row bg-primary text-white">
                    <center><h1>Attachment Dokumen</h1></center>
                </div>
                <div className="row">
                    &nbsp;
                </div>
                <div className="row">
                    <div className="col-6">
                        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalDefault"
                            onClick={this.adddata.bind(this)}>
                            Add
                        </button>
                    </div>
                </div>
                
                <div className="row">
                    &nbsp;
                </div>

                <div className="row">                    
                    <table className="table">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th className="Col-klik">Nama Dokumen</th>
                                <th className="Col-klik">Kategori Dokumen</th>
                                <th className="Col-klik">Dokumen</th>
                                <th className="Col-klik">Exp Date</th>
                                {/* <th>Status</th> */}
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { AttachVendor }
                        </tbody>
                    </table>
                </div>
                <div className="row"> 
                    <div className="text-center">
                        <input type="checkbox" />&nbsp;
                        <label> Anda Setuju Bahwa Data Anda yang Anda Masukkan telah benar, jika terdapat</label>
                    </div>
                    <div className="text-center">
                        &nbsp;
                        <label> kekeliruan maka proses akan berlangsung sesuai dengan hukum yang berlaku</label>
                    </div>

                </div>    
                <div className="row"> 
                    &nbsp;
                </div>    
                <div className="row"> 
                    <div className="text-center">
                        <button className="btn btn-primary btn-lg"
                        onClick={this.handleRegisNext.bind(this)} >Submit</button>
                    </div>
                </div>    

            </div>
            <ModalComponent title="Deleted Confirmation" footerContent={this.modalFooterDeleted()} content={this.modalDeleted()} dataBsBackdrop="static" id="deletedModal"/>
            <ModalComponent title="Add Group Users" footerContent={this.modalFooterContent()} content={this.modalContent()} dataBsBackdrop="static" id="exampleModalDefault"/>
                

            {this.state.alertSucces && 
                <div className="ModalAlert">
                    <div className="alertNew">
                        <div className="modal-content">                   
                            <div className={`modal-header ${this.state.bgalert}`}>
                                <h5 className="modal-title" id="exampleModalLabel">Alert Message</h5>
                                <button type="button" className="btn-close" aria-label="Close"
                                    onClick={(e) => this.setState({alertSucces : false})}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div className="modal-body text-black">
                                <center><h1>{ this.state.alermessage }</h1></center>
                            </div>            
                            <div style={{width:"100%"}}>
                                <center>
                                <button className={`btn ${this.state.btnalert}`} onClick={(e) => this.setState({alertSucces : false})}>
                                    OK</button> 
                                </center>
                            </div>
                            <br></br>
                        </div>
                    </div>
                </div>
                }
        </>
    }
}

export default regisvendorLayout(IzinVendorPage);