import 'font-awesome/css/font-awesome.min.css';
import './assets/css/app.css';
import DashboardPage from './pages/DashboardPage';
import TypographyPage from './pages/TypographyPage';
import UsersPage from './pages/UsersPage';
import GroupUsersPage from './pages/GroupUsersPage';
import GroupMenuPage from './pages/GroupMenuPage';
import RolesPage from './pages/RolesPage';

import LoginPage from './pages/auth/LoginPage';
import ChangedPassword from './pages/auth/ChangedPasswordPage';
import ResetPassword from './pages/auth/ResetPasswordPage';
import ForgotPassword from './pages/auth/ResetPassword';

import ProfilePage from './pages/profile/ProfilePage';

import ChangePasswordPage from './pages/profile/ChangePasswordPage';
import UserPreferencesPage from './pages/profile/UserPreferencesPage'
import AdminBlankPage from './pages/AdminBlankPage';

import VendorPage from './pages/vendor/VendorPage';

import RegisVendorPage from './pages/vendor/registration/ProfileVendorPage';
import BankVendorPage from './pages/vendor/registration/BankVendorPage';
import IzinVendorPage from './pages/vendor/registration/IzinVendorPage';
import LandasanVendorPage from './pages/vendor/registration/LandasanVendorPage';
import PengurusVendorPage from './pages/vendor/registration/PengurusVendorPage';
import AhliVendorPage from './pages/vendor/registration/AhliVendorPage';
import SPTVendorPage from './pages/vendor/registration/SPTVendorPage';
import PengalamanVendorPage from './pages/vendor/registration/PengalamanVendorPage';
import AttachVendorPage from './pages/vendor/registration/AttachVendorPage';

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

function App() {
  return (
        <Router>
            <Routes>
                {/* <Route exact path='/' element={<DashboardPage/>} />
                <Route exact path='/login' element={<LoginPage/>} /> */}
                <Route exact path='/Admin' element={<LoginPage/>} />
                <Route exact path='/' element={<LoginPage/>} />
                <Route exact path='/home' element={<DashboardPage/>} />
                <Route exact path='/forgot-password' element={<ForgotPassword/>} />
                <Route exact path='/profile' element={<ProfilePage/>} />
                <Route exact path='/resetpassword' element={<ResetPassword/>} />
                <Route exact path='/changepassword' element={<ChangedPassword/>} />
                <Route exact path='/change-password' element={<ChangePasswordPage/>} />
                <Route exact path='/preferences' element={<UserPreferencesPage/>} />
                <Route exact path='/typography' element={<TypographyPage/>} />
                <Route exact path='/users' element={<UsersPage/>} />
                <Route exact path='/groupusers' element={<GroupUsersPage/>} />
                <Route exact path='/groupmenu' element={<GroupMenuPage/>} />
                <Route exact path='/roles' element={<RolesPage/>} />
                <Route exact path='/blank-page' element={<AdminBlankPage/>} />
                <Route exact path='/vendor' element={<VendorPage/>} />

                {/* <Route exact path='/RegisVendor' element={<RegisVendorPage/>} /> */}
                <Route exact path='/RegisVendorBank' element={<BankVendorPage/>} />
                <Route exact path='/RegisVendorIzin' element={<IzinVendorPage/>} />
                <Route exact path='/RegisVendorLandasan' element={<LandasanVendorPage/>} />
                <Route exact path='/RegisVendorPengurus' element={<PengurusVendorPage/>} />
                <Route exact path='/RegisVendorAhli' element={<AhliVendorPage/>} />
                <Route exact path='/RegisVendorSPT' element={<SPTVendorPage/>} />
                <Route exact path='/RegisVendorPengalaman' element={<PengalamanVendorPage/>} />
                <Route exact path='/RegisVendorAttach' element={<AttachVendorPage/>} />
            </Routes>
        </Router>
    )
}

export default App;
