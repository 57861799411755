import React from "react";
import adminLayout from "../hoc/adminLayout";

class GroupMenuPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            groupuserdata: [],
            menudata: [],
            id : 0,
            group_name:'',
            group_users:'',
            stsedit:'0',
            add: false,
            edit: false,
            delete: false,
            approve: false,
            ignore: false,
            export: false,
            alertSucces: false,
            alermessage: '',
            bgalert: 'bg-primary',
            btnalert: 'btn-primary'
        };
        this.handleSave = this.handleSave.bind(this);
        this.GroupUserList = this.GroupUserList.bind(this);
        this.MenuList = this.MenuList.bind(this);
    }

    componentDidMount() {
        this.GroupUserList();
        this.MenuList();
        const usAccess = JSON.parse(localStorage.getItem('UsAccess'));
        const MenuAcces = usAccess.filter(i => i.urlto == 'groupusers');
        this.setState({
            add : MenuAcces[0].madd,
            edit : MenuAcces[0].medit,
            delete : MenuAcces[0].mdelete,
            approve : MenuAcces[0].mapprove,
            ignore : MenuAcces[0].mignore,
            export : MenuAcces[0].mexport
        })
    }
      
    GroupUserList() {
        fetch('https://admin.delpis.online/api/groupusers/combo')
        .then((response) => response.json())
        .then((data) => this.setState({groupuserdata : data['data']  || []}))
    }
    
    MenuList(grupid) {
        this.setState({group_users : grupid});
        fetch("https://admin.delpis.online/api/groupmenu/view", {
            method: "post",
            body: JSON.stringify({
                group_id: grupid
            }),
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((data) => this.setState({menudata : data['data'] || []}))
    }

    viewedit(item){
        this.setState({
            id : item.id,
            group_name : item.group_name,
            stsedit : '1'
        })
    }

    savedata(){
        console.log(this.state.menudata);
    }

    handleSave(event){
        event.preventDefault();
        const data = this.state.menudata;
        const usergrop = this.state.group_users;
        const usToken = localStorage.getItem('UsToken');    
        
        this.setState({
            bgalert: "bg-warning",
            btnalert: "btn-warning"
        })  

        if(usergrop == "" || usergrop == null){
            this.setState({
                alertSucces : true,
                alermessage: "Group Name harus di isi"
            })  
            // alert("Username harus di isi");
            return;
        }else{
            fetch("https://admin.delpis.online/api/groupmenu/simpan", {
                method: "post",
                body: JSON.stringify({
                    data: data,
                    usergroup: usergrop,
                    token: usToken
                }),
                headers:{
                    'Content-Type': 'application/json'
                }
            })
    
            .then(() => {
                this.setState({
                    alertSucces : true,
                    bgalert: "bg-success",
                    btnalert: "btn-success"
                })  
                this.setState({
                    alertSucces : true,
                    alermessage: "Updated Success"
                })  
            })
            .catch(function(error) {
                console.log("Request failed", error);
            });
        }            
    }

    handleChange(no, ischecked, kat){
        console.log(no);
        var x = no-2;
        const newState = this.state.menudata;
        if(kat === 'mview'){
            newState[x].mview = ischecked; 
        }
        if(kat === 'madd'){
            newState[x].madd = ischecked; 
        }
        if(kat === 'medit'){
            newState[x].medit = ischecked; 
        }
        if(kat === 'mdelete'){
            newState[x].mdelete = ischecked; 
        }
        if(kat === 'mapprove'){
            newState[x].mapprove = ischecked; 
        }
        if(kat === 'mignore'){
            newState[x].mignore = ischecked; 
        }
        if(kat === 'mexport'){
            newState[x].mexport = ischecked; 
        }
        this.setState(newState);
        // setState(this.state.menudata.map(items => items.menu_id === item.menu_id ? {...items, items : ischecked} : items))
        console.log(this.state.menudata);
    }
    
    render(){
        var no = 1;
        const groupuserdata = this.state.groupuserdata.map((item, i) => (
            <option value={ item.id }>{ item.group_name }</option>
        ));
        
        const menudata = this.state.menudata.map((item, i) => (
            <tr>
                <td>{ no++ }</td>
                <td>
                    { 
                        item.urlto === '#' && item.level_menu === 1? (
                            <span><b>{item.menu_name}</b></span>
                        ) : (
                            item.urlto === '#' && item.level_menu === 2? (
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{item.menu_name}</b></span>
                            ) : (
                                item.level_menu === 3? (
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.menu_name}</span>
                                ) : (
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.menu_name}</span>
                                )
                            )
                        )
                    }
                </td>
                <td align="center">
                    {
                        this.state.edit ? (
                            <input type="checkbox" checked={item.mview}
                                // onChange={(e) => this.handleChange.bind(this, item, e.currentTarget.checked)}/>
                                onChange={this.handleChange.bind(this,no,!item.mview, 'mview')}/>
                        ) : (
                            <input type="checkbox" checked={item.mview} disabled/>
                        )
                    }
                </td>
                <td align="center">
                    {
                        item.urlto === '#' ? (
                            ''
                        ) : (
                            this.state.edit ? (
                                <input type="checkbox" checked={item.madd}
                                onChange={this.handleChange.bind(this,no,!item.madd, 'madd')}/>
                            ) : (
                                <input type="checkbox" checked={item.madd} disabled/>
                            )
                        )
                    }
                </td>    
                <td align="center">
                    {
                        item.urlto === '#' ? (
                            ''
                        ) : (
                            this.state.edit ? (
                                <input type="checkbox" checked={item.medit}
                                onChange={this.handleChange.bind(this,no,!item.medit, 'medit')}/>
                            ) : (
                                <input type="checkbox" checked={item.medit} disabled/>
                            )
                        )
                    }                    
                </td>
                <td align="center">
                    {
                        item.urlto === '#' ? (
                            ''
                        ) : (
                            this.state.edit ? (
                                    <input type="checkbox" checked={item.mdelete}
                                    onChange={this.handleChange.bind(this,no,!item.mdelete, 'mdelete')}/>
                                ) : (
                                    <input type="checkbox" checked={item.mdelete} disabled/>
                                )
                            )
                        }
                </td>
                <td align="center">
                    {
                        item.urlto === '#' ? (
                            ''
                        ) : (
                            this.state.edit ? (
                                <input type="checkbox" checked={item.mapprove}
                                onChange={this.handleChange.bind(this,no,!item.mapprove, 'mapprove')}/>
                            ) : (
                                <input type="checkbox" checked={item.mapprove} disabled/>
                            )
                        )
                    }
                </td>
                <td align="center">
                    {
                        item.urlto === '#' ? (
                            ''
                        ) : (
                            this.state.edit ? (
                                <input type="checkbox" checked={item.mignore}
                                onChange={this.handleChange.bind(this,no,!item.mignore, 'mignore')}/>
                            ) : (
                                <input type="checkbox" checked={item.mignore} disabled/>
                            )
                        )
                    }
                </td>
                <td align="center">
                    {
                        item.urlto === '#' ? (
                            ''
                        ) : (
                            this.state.edit ? (
                                <input type="checkbox" checked={item.mexport}
                                onChange={this.handleChange.bind(this,no,!item.mexport, 'mexport')}/>
                            ) : (
                                <input type="checkbox" checked={item.mexport} disabled/>
                            )
                        )
                    }
                </td>
            </tr>
        ));
        
        return (
            <>
            
            <div>
                <div className="bd-example">
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">User Management</a></li>
                    <li className="breadcrumb-item"><a href="#">Menu Akses</a></li>
                </ol>
                </nav>
                </div>
            </div>
            <div className="table-container">
                <div className="row">
                    <div className="col-12">
                        <label htmlFor="validationServer04" className="form-label">Group Users</label>
                        <select className="form-select is-invalid" id="validationServer04" required=""
                            onChange={(e) => this.MenuList(e.target.value)}>
                            <option disabled="" value="0">Choose...</option>
                            { groupuserdata }
                        </select>
                    </div>
                </div>
                <div className="row">
                    &nbsp;
                </div>
                <div className="row">
                    <div className="col-12">
                        <button type="button" className="btn btn-success"
                             onClick={this.handleSave}>
                            Save
                        </button>
                    </div>
                </div>

                {this.state.alertSucces && 
                <div className="ModalAlert">
                    <div className="alertNew">
                        <div className="modal-content">                   
                            <div className={`modal-header ${this.state.bgalert}`}>
                                <h5 className="modal-title" id="exampleModalLabel">Alert Message</h5>
                                <button type="button" className="btn-close" aria-label="Close"
                                    onClick={(e) => this.setState({alertSucces : false})}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div className="modal-body text-black">
                                <center><h1>{ this.state.alermessage }</h1></center>
                            </div>            
                            <div style={{width:"100%"}}>
                                <center>
                                <button className={`btn ${this.state.btnalert}`} onClick={(e) => this.setState({alertSucces : false})}>
                                    OK</button> 
                                </center>
                            </div>
                            <br></br>
                        </div>
                    </div>
                </div>
                }
                                    
                <div className="row">
                    <div className="col-12">
                        &nbsp;
                    </div>
                </div>
                <div className="d-flex text-muted">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Menu Name</th>
                                <th>View </th>
                                <th>Add </th>
                                <th>Edit </th>
                                <th>Delete </th>
                                <th>Approve </th>
                                <th>Ignore </th>
                                <th>Export </th>
                            </tr>
                        </thead>
                        <tbody>
                            { menudata }
                        </tbody>
                    </table>
                </div>
            </div>
            </>
          );
    }
}

export default adminLayout(GroupMenuPage);