import React from "react";
import adminLayout from "../hoc/adminLayout";
import ModalComponent from "../components/ModalComponent";

class UsersPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            groupuserdata: [],
            person: [],
            username:'',
            email:'',
            firstname:'',
            lastname:'',
            contactnumber:'',
            // nik:'',
            // npwp:'',            
            stsedit:'0',
            password:'12345678',
            group_users:'',
            iduser : 0,
            search: '',
            page: 1,
            rowpage: 10,
            jumData: 0,
            jumPage: 0,
            add: false,
            edit: false,
            delete: false,
            approve: false,
            ignore: false,
            export: false,
            alertSucces: false,
            alermessage: '',
            bgalert: 'bg-primary',
            btnalert: 'btn-primary',
            ordby: 'id',
            ordasc: 'asc'
        };
        this.handleSave = this.handleSave.bind(this);
        this.SearchBy = this.SearchBy.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.UserList = this.UserList.bind(this);
        this.OrderBy = this.OrderBy.bind(this);
        this.GroupUserList = this.GroupUserList.bind(this);
    }

    componentDidMount() {
        this.UserList();
        this.GroupUserList();
        const usAccess = JSON.parse(localStorage.getItem('UsAccess'));
        const MenuAcces = usAccess.filter(i => i.urlto == 'users');
        this.setState({
            add : MenuAcces[0].madd,
            edit : MenuAcces[0].medit,
            delete : MenuAcces[0].mdelete,
            approve : MenuAcces[0].mapprove,
            ignore : MenuAcces[0].mignore,
            export : MenuAcces[0].mexport
        })
    }

    PageList(page){
        console.log(page);
        this.setState({
            page : page || 0
        })       
        fetch("https://admin.delpis.online/api/users/view", {
            method: "post",
            body: JSON.stringify({
                page: page,
                rowpage: this.state.rowpage,
                ordby: this.state.ordby,
                ordasc: this.state.ordasc
            }),
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((response) => {
            this.setState({
                person : response['data'] || [],
                jumData: response['jumData'] || 0,
                jumPage: response['jumPage'] || 0
            })
        })
    }
      
    OrderBy(ordby) {    
        console.log(this.state.ordby + ' = ' + ordby)
        if(this.state.ordby !== ordby){
            this.setState({ordasc : 'asc'})
        }else{
            if(this.state.ordasc === "asc"){
                this.setState({ordasc : 'desc'})
            }else{
                this.setState({ordasc : 'asc'})            
            }         
        }
        
        this.setState({
            ordby : ordby || ''
        })
        fetch("https://admin.delpis.online/api/users/view", {
            method: "post",
            body: JSON.stringify({
                page: this.state.page,
                rowpage: this.state.rowpage,
                ordby: ordby,
                ordasc: this.state.ordasc
            }),
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((response) => {
            this.setState({
                person : response['data'] || [],
                jumData: response['jumData'] || 0,
                jumPage: response['jumPage'] || 0
            })
        })
    }
      
    UserList() {           
        fetch("https://admin.delpis.online/api/users/view", {
            method: "post",
            body: JSON.stringify({
                page: this.state.page,
                rowpage: this.state.rowpage,
                ordby: this.state.ordby,
                ordasc: this.state.ordasc
            }),
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((response) => {
            this.setState({
                person : response['data'] || [],
                jumData: response['jumData'] || 0,
                jumPage: response['jumPage'] || 0
            })
        })
    }
      
    GroupUserList() {
        fetch('https://admin.delpis.online/api/groupusers/combo')
        .then((response) => response.json())
        .then((response) => {
            this.setState({
                groupuserdata : response['data'] || []
            })
        })
        .catch(function(error) {
            console.log("View failed", error);
        });
    }

    SearchBy(event){
        const usToken = localStorage.getItem('UsToken'); 
                   
        fetch("https://admin.delpis.online/api/users/search", {
            method: "post",
            body: JSON.stringify({
                search: this.state.search
            }),
            headers:{
                'Content-Type': 'application/json'
            }
        })        
        .then(response => response.json())
        .then((response) => {
            if(response['status'] === false){
                this.setState({
                    alertSucces : true,
                    alermessage: response['message'],
                    bgalert: "bg-warning",
                    btnalert: "btn-warning"
                })  
                // alert(response['message']);
            }else{
                this.setState({person : response['data'] || []})
            }
        })
        .catch(function(error) {
            console.log("Search failed", error);
        });
        // this.setState({person})
    }

    viewedit(item){
        this.adddata();
        // console.log(item.npwp);
        this.setState({
            username : item.user_name || '',
            firstname : item.firstname || '',
            lastname : item.lastname || '',
            email : item.email || '',
            contactnumber : item.phone || '',
            gender : item.sex,
            // nik : item.id_card || '',
            // npwp : item.npwp || '',
            stsedit : '1',
            iduser : item.id,
            group_users : item.group_users_id
        })
    }

    adddata(){
        this.setState({
            username:'',
            email:'',
            firstname:'',
            lastname:'',
            contactnumber:'',
            gender:'',
            // nik:'',
            // npwp:'',            
            stsedit:'0',
            group_users:'',
            iduser : 0
        })
    }

    delete(iduser){
        this.setState({
            iduser : iduser
        })          
    }

    handleDelete(event){
        const usToken = localStorage.getItem('UsToken'); 
                   
        fetch("https://admin.delpis.online/api/users/delete", {
            method: "post",
            body: JSON.stringify({
                iduser: this.state.iduser,
                token: usToken
            }),
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(() => {
            const data = this.state.person.filter(i => i.id !== this.state.iduser)
            this.setState({
                person : data,
                alertSucces : true,
                alermessage: "Deleted Succes",
                bgalert: "bg-danger",
                btnalert: "btn-danger"})
            // console.log("Deleted succeeded with  response");
            // alert("Deleted Succes");
        })
        .catch(function(error) {
            console.log("Deleted failed", error);
        });
        // this.setState({person})
    }

    handleSave(event){
        event.preventDefault();
            const username = this.state.username;
            const email = this.state.email;
            const password = this.state.password;
            const usToken = localStorage.getItem('UsToken');    
            // const datsim = { username, email, password }
            // console.log(datsim);
            this.setState({
                bgalert: "bg-warning",
                btnalert: "btn-warning"
            })  

            if(username == "" || username == null){
                this.setState({
                    alertSucces : true,
                    alermessage: "Username harus di isi"
                })  
                // alert("Username harus di isi");
                return;
            }

            if(email == "" || email == null){
                this.setState({
                    alertSucces : true,
                    alermessage: "Email harus di isi"
                })  
                // alert("Email harus di isi");
                return;
            }

            // if(this.state.nik == "" || this.state.nik == null){
            //     this.setState({
            //         alertSucces : true,
            //         alermessage: "NIK harus di isi"
            //     })  
            //     // alert("NIK harus di isi");
            //     return;
            // }

            if(this.state.gender == "" || this.state.gender == null){
                this.setState({
                    alertSucces : true,
                    alermessage: "Gender harus di isi"
                })  
                // alert("Group Users harus di isi");
                return;
            }

            if(this.state.firstname == "" || this.state.firstname == null){
                this.setState({
                    alertSucces : true,
                    alermessage: "Firstname harus di isi"
                })  
                alert("Firstname harus di isi");
                return;
            }

            if(this.state.lastname == "" || this.state.lastname == null){
                this.setState({
                    alertSucces : true,
                    alermessage: "Lastname harus di isi"
                })  
                // alert("Lastname harus di isi");
                return;
            }

            if(this.state.contactnumber == "" || this.state.contactnumber == null){
                this.setState({
                    alertSucces : true,
                    alermessage: "Contact Number harus di isi"
                })  
                // alert("Contact Number harus di isi");
                return;
            }

            if(this.state.group_users == "" || this.state.group_users == null){
                this.setState({
                    alertSucces : true,
                    alermessage: "Group Users harus di isi"
                })  
                // alert("Group Users harus di isi");
                return;
            }
            

            if(this.state.stsedit == '1'){
                fetch("https://admin.delpis.online/api/users/update", {
                    method: "post",
                    body: JSON.stringify({
                        id: this.state.iduser, 
                        username: username, 
                        email: email, 
                        password: username, 
                        // id_card: this.state.nik,
                        // npwp: this.state.npwp,
                        firstname: this.state.firstname,
                        lastname: this.state.lastname,
                        sex: this.state.gender,
                        phone: this.state.contactnumber,
                        groupuser: this.state.group_users,
                        token: usToken
                    }),
                    headers:{
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => response.json())
                .then((response) => {
                    if(response['status'] === false){
                        this.setState({
                            alertSucces : true,
                            alermessage: response['message'],
                            bgalert: "bg-warning",
                            btnalert: "btn-warning"
                        })  
                        // alert(response['message']);
                    }else{
                        this.UserList();
                        // console.log("Request succeeded with  response");
                        this.setState({
                            alertSucces : true,
                            alermessage: "Updated Succes",
                            bgalert: "bg-success",
                            btnalert: "btn-success"
                        })  
                        // alert("Updated Succes");
                        this.TombolElement.click();
                    }
                })
                .catch(function(error) {
                    console.log("Request failed", error);
                });
            }else{                
                fetch("https://admin.delpis.online/api/users/register", {
                    method: "post",
                    body: JSON.stringify({
                        username: username,
                        email: email,
                        password: username,
                        // id_card: this.state.nik,
                        // npwp: this.state.npwp,
                        firstname: this.state.firstname,
                        lastname: this.state.lastname,
                        phone: this.state.contactnumber,
                        sex: this.state.gender,
                        groupuser: this.state.group_users,
                        token: usToken
                    }),
                    headers:{
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then((response) => {
                    if(response['status'] === false){
                        this.setState({
                            alertSucces : true,
                            alermessage: response['message'],
                            bgalert: "bg-warning",
                            btnalert: "btn-warning"
                        })  
                        // alert(response['message']);
                    }else{
                        this.UserList();
                        // console.log("Request succeeded with  response");
                        this.setState({
                            alertSucces : true,
                            alermessage: "Save Succes",
                            bgalert: "bg-success",
                            btnalert: "btn-success"
                        })  
                        // alert("Save Succes");
                        this.TombolElement.click();
                    }
                })
                .catch(function(error) {
                    console.log("Request failed", error);
                });
            }
    }

    modalFooterDeleted(){      

        return <>
            <div style={{width:"100%"}}>
                <button className="btn btn-success" onClick={this.handleDelete}
                    data-bs-dismiss="modal">Yes</button>
            &nbsp;
            <button className="btn btn-danger"
                data-bs-dismiss="modal">No</button>
            </div>
        </>;
    }

    modalDeleted(){
        return <>
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                <form>
                    <div className="mb-3">
                        <label htmlFor="group_name" className="form-label">Apakah anda yakin ingin menghapus data?</label>
                    </div>
                </form>
            </div>
        </>;
    }
    
    modalFooterContent(){      

        return <>
            <div style={{width:"100%"}}>
                <button className="btn btn-success" onClick={this.handleSave}>Save</button>
                &nbsp; 
                <button className="btn btn-danger" ref={tombol => this.TombolElement = tombol}
                    data-bs-dismiss="modal">Cancel</button> 
            </div>
        </>;
    }

    modalContent(){
        const groupuserdata = this.state.groupuserdata.map((item, i) => (
            item.id == this.state.group_users?
                <option value={ item.id } selected>{ item.group_name }</option>
            :
                <option value={ item.id }>{ item.group_name }</option>
        ));
        return <>
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            </ul>
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                    <form>
                        <div className="mb-3">
                            <label htmlFor="exampleInputusername" className="form-label">Username <span className="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="username" aria-describedby="Username"
                                onChange={(e) => this.setState({username : e.target.value})}
                                value={ this.state.username }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputfirstname" className="form-label">First Name <span className="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="firstname" aria-describedby="firstname"
                                onChange={(e) => this.setState({firstname : e.target.value})}
                                value={ this.state.firstname }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputlastname" className="form-label">Last Name <span className="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="text" className="form-control" id="lastname" aria-describedby="lastname"
                                onChange={(e) => this.setState({lastname : e.target.value})}
                                value={ this.state.lastname }/>
                        </div>
                        <div className="mb-3">
                            <label className="form-check-label" htmlFor="male">Gender <span className="symbol required" style={{color:'red'}}>*</span></label>
                            &nbsp;
                            <input type="radio" name="radios" className="form-check-input" id="male" value="Male" disabled="" 
                                onChange={(e) => this.setState({gender : e.target.value})}
                                checked={this.state.gender == "Male"}/>
                            &nbsp;<label className="form-check-label" htmlFor="male">Male</label>
                            &nbsp;&nbsp;
                            <input type="radio" name="radios" className="form-check-input" id="female" value="Female" disabled="" 
                                onChange={(e) => this.setState({gender : e.target.value})}
                                checked={this.state.gender == "Female"}/>
                            &nbsp;<label className="form-check-label" htmlFor="female">Female</label>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="contactnumber" className="form-label">Contact Number <span className="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="number" className="form-control" id="contactnumber" aria-describedby="contactnumber" 
                                onChange={(e) => this.setState({contactnumber : e.target.value})}
                                value={ this.state.contactnumber }/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email address <span className="symbol required" style={{color:'red'}}>*</span></label>
                            <input type="email" className="form-control" id="email" aria-describedby="emailHelp"
                                onChange={(e) => this.setState({email : e.target.value})} 
                                value={ this.state.email }/>
                        </div>
                        {/* <div className="mb-3">
                            <label htmlFor="validationServer04" className="form-label">Group Users <span className="symbol required" style={{color:'red'}}>*</span></label>
                            <select className="form-select is-invalid" id="validationServer04" required=""
                                onChange={(e) => this.setState({group_users : e.target.value})}>
                                <option disabled="" value="0">Choose...</option>
                                <li key={groupuserdata.iduser} >{groupuserdata}</li>
                            </select>
                        </div> */}

                        <div className="mb-3">
                            <label htmlFor="validationServer04" className="form-label">Group Users</label>
                            <select className="form-select is-invalid" id="validationServer04" required=""
                                onChange={(e) => this.setState({group_users : e.target.value})}>
                                <option disabled="" value="0">Choose...</option>
                                { groupuserdata }
                            </select>
                        </div>

                    </form>
                </div>
            </div>
        </>;
    }

    render(){
        var no = ((this.state.page - 1) * this.state.rowpage) + 1;
        
        const paging = [];
        for(let j = 1; j <= this.state.jumPage; j++) {
            // console.log(j);
            paging.push(<li className="page-item"><a className="page-link" href="#"
                            onClick={this.PageList.bind(this, j)}>{j}</a></li>);
        }
        // console.log(paging);
        const persons = this.state.person.map((item, i) => (

            <tr>
                <td>{ no++ }</td>
                <td>{ item.user_name }</td>
                <td>{ item.firstname } { item.lastname }</td>
                <td>{ item.email }</td>
                {/* <td>&nbsp;</td> */}
                <td>{ item.group_name }</td>
                {/* <td>{ item.status }</td> */}
                <td>
                    <div className="dropdown table-action-dropdown">
                        <button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButtonSM" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v" aria-hidden="true"></i></button>
                        <ul className="dropdown-menu dropdown-prof" aria-labelledby="dropdownMenuButtonSM">
                            {this.state.edit ? (
                                <li><a className="dropdown-item" href="#" onClick={this.viewedit.bind(this, item)}
                                data-bs-toggle="modal" data-bs-target="#exampleModalDefault">
                               <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Edit</a></li>
                            ) : (
                                ''
                            )}
                            <div className="dropdown-divider"></div>        
                            {this.state.delete ? (
                                <li><a className="dropdown-item text-danger" href="#" onClick={this.delete.bind(this, item.id)}
                                data-bs-toggle="modal" data-bs-target="#deletedModal">
                                <i className="fa fa-trash" aria-hidden="true"></i>&nbsp;Delete</a></li>
                            ) : (
                                ''
                            )}                    
                            
                        </ul>
                    </div>
                </td>
            </tr>
        ));
        
        return (
            <>
            
            <div>
                <div className="bd-example">
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">User Management</a></li>
                    <li className="breadcrumb-item"><a href="#">Users</a></li>
                </ol>
                </nav>
                </div>
            </div>
            <div className="table-container">
                <div className="row">
                    <div className="col-12">
                        {this.state.add ? (
                            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalDefault"
                                onClick={this.adddata.bind(this)}>
                                Add User
                            </button>
                        ) : (
                            ''
                        )}

                        <ModalComponent title="Deleted Confirmation" footerContent={this.modalFooterDeleted()} content={this.modalDeleted()} dataBsBackdrop="static" id="deletedModal"/>
                        <ModalComponent title="Add Users" footerContent={this.modalFooterContent()} content={this.modalContent()} dataBsBackdrop="static" id="exampleModalDefault"/>
                    </div>
                </div>
                <div className="row">
                    &nbsp;
                </div>
                <div className="row">
                    <div className="col-5">
                        <input type="text" className="form-control" id="search" aria-describedby="search" 
                            onChange={(e) => this.setState({search : e.target.value})}
                            value={ this.state.search }/>
                    </div>
                    <div className="col-3">
                        <button type="button" className="btn btn-primary" onClick={this.SearchBy.bind(this)}>
                            Search
                        </button>
                    </div>
                </div>
                                    
                <div className="row">
                    <div className="col-12">
                        &nbsp;
                    </div>
                </div>

                {this.state.alertSucces && 
                <div className="ModalAlert">
                    <div className="alertNew">
                        <div className="modal-content">                   
                            <div className={`modal-header ${this.state.bgalert}`}>
                                <h5 className="modal-title" id="exampleModalLabel">Alert Message</h5>
                                <button type="button" className="btn-close" aria-label="Close"
                                    onClick={(e) => this.setState({alertSucces : false})}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div className="modal-body text-black">
                                <center><h1>{ this.state.alermessage }</h1></center>
                            </div>            
                            <div style={{width:"100%"}}>
                                <center>
                                <button className={`btn ${this.state.btnalert}`} onClick={(e) => this.setState({alertSucces : false})}>
                                    OK</button> 
                                </center>
                            </div>
                            <br></br>
                        </div>
                    </div>
                </div>
                }

                <div className="d-flex text-muted">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th className="Col-klik" onClick={(e) => this.OrderBy('user_name')}>Username &nbsp;&nbsp;<img src={require('./../assets/images/arrow.png')} alt="" height='25' width='25'></img></th>
                                <th className="Col-klik" onClick={(e) => this.OrderBy('firstname')}>Full Name &nbsp;&nbsp;<img src={require('./../assets/images/arrow.png')} alt="" height='25' width='25'></img></th>
                                <th className="Col-klik" onClick={(e) => this.OrderBy('email')}>Email &nbsp;&nbsp;<img src={require('./../assets/images/arrow.png')} alt="" height='25' width='25'></img></th>
                                {/* <th>Customer Code</th> */}
                                <th className="Col-klik" onClick={(e) => this.OrderBy('group_users_id')}>Role Level &nbsp;&nbsp;<img src={require('./../assets/images/arrow.png')} alt="" height='25' width='25'></img></th>
                                {/* <th>Status</th> */}
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { persons }
                        </tbody>
                    </table>
                </div>
                <nav className="table-bottom-center-pagination" aria-label="Page navigation example ">
                    <ul className="pagination">
                        {this.state.page === 1 ? (
                            ''
                        ):(
                            <li className="page-item">
                            <a className="page-link" href="#" aria-label="Previous"
                                onClick={this.PageList.bind(this, this.state.page - 1)}>
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Previous</span>
                            </a>
                            </li>
                        )}
                        { paging }
                        {/* <li className="page-item"><a className="page-link" href="#">1</a></li>
                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                        <li className="page-item"><a className="page-link" href="#">3</a></li> */}
                        {this.state.page === this.state.jumPage ? (
                            ''
                        ):(
                            <li className="page-item">
                            <a className="page-link" href="#" aria-label="Next"
                                onClick={this.PageList.bind(this, this.state.page + 1)}>
                                <span aria-hidden="true">&raquo;</span>
                                <span className="sr-only">Next</span>
                            </a>
                            </li>
                        )}
                    </ul>
                </nav>
            </div>
            </>
          );
    }
}

export default adminLayout(UsersPage);